import * as React from "react";
import { Box, AppBar, Tab, Tabs, Typography } from "@material-ui/core";
import globalService from "../../services/globalService";
import { toast } from "react-toastify";
import { appString } from "../../common/appString";
import FetchingData from "../../components/FetchingData";
import NoDataFound from "../../components/NoDataFound";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface MyBookingProps {}

export interface MyBookingState {}

class MyBooking extends React.Component<MyBookingProps, MyBookingState> {
  state = {
    currentTab: 0,
    upcomingList: [],
    historyList: [],
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    isLoading: false,
  };

  componentDidMount() {
    this.getUserDetail();
  }

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user }, this.getBooking);
      //   this.getBooking();
    } else this.setState({ isLoggedIn: false });
  }

  getBooking = async () => {
    const { userDetail } = this.state;

    let post = {
      UserId: userDetail.UserInfo["Id"],
    };

    this.setState({ isLoading: true });
    const { data: resp } = await globalService.getBookingByUser(post);
    if (resp.status === "success") {
      //   toast.success("booking list success");
      this.setState({
        upcomingList: resp.response.Upcoming,
        historyList: resp.response.History,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      toast.error(resp.response);
    }
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ currentTab: newValue });
  };
  render() {
    const { currentTab } = this.state;
    return (
      <React.Fragment>
        <div className="nav-ext d-none d-sm-block"></div>

        <div className="container mb-5">
          <Box
            boxShadow={4}
            style={{
              background: "rgba(255,255,255,0.6)",
              borderRadius: 3,
            }}
          >
            {/* <div className="bg-primary text-center">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div> */}
            <AppBar position="static" color="default">
              <Tabs
                value={currentTab}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Upcoming" {...a11yProps(0)} />
                <Tab label="History" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
              {this.renderBooking()}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {this.renderHistory()}
            </TabPanel>
          </Box>
        </div>
      </React.Fragment>
    );
  }

  renderBooking() {
    const { upcomingList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <FetchingData />
        ) : upcomingList.length === 0 ? (
          <NoDataFound />
        ) : (
          ""
        )}

        {upcomingList.map((val: any, idx) => {
          return (
            <div
              key={idx}
              className="row border bDetail bg-white my-2 p-1 shadow-sm"
            >
              <div className="col-12 bg-light">
                <h4 className="text-primary">
                  {val.Model + " " + val.Brand}{" "}
                  <span className="h6 text-secondary">{val.CarType}</span>{" "}
                </h4>
              </div>

              <div className="col-12 col-md-3" style={{ overflow: "hidden" }}>
                <div className="ng-star-inserted">
                  <img
                    alt=""
                    className="w-100"
                    src={appString.IMAGE_PATH + val.CarImages}
                  />
                </div>
              </div>
              <div className="col-12 col-md-7  justify-content-center align-content-end">
                <div className="row my-2 text-muted">
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking From</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingFrom)}</div>
                  </div>
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking Till</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingTo)}</div>
                  </div>

                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Amount</strong>
                      {val.BookingAmount} KWD
                    </span>
                  </div>
                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Status</strong> {val.Status}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <small>
                      {" "}
                      Booked On: {globalService.formatDate(val.BookedOn)}
                    </small>
                  </div>
                  <div className="col text-right">
                    <Link to={"/account/booking-detail/" + val.Id}>
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  renderHistory() {
    const { historyList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <FetchingData />
        ) : historyList.length === 0 ? (
          <NoDataFound />
        ) : (
          ""
        )}

        {historyList.map((val: any, idx) => {
          return (
            <div
              key={idx}
              className="row border bDetail bg-white my-2 p-1 shadow-sm"
            >
              <div className="col-12 bg-light">
                <h4 className="text-primary">
                  {val.Model + " " + val.Brand}{" "}
                  <span className="h6 text-secondary">{val.CarType}</span>{" "}
                </h4>
              </div>

              <div className="col-12 col-md-3" style={{ overflow: "hidden" }}>
                <div className="ng-star-inserted">
                  <img
                    alt=""
                    className="w-100"
                    src={appString.IMAGE_PATH + val.CarImages}
                  />
                </div>
              </div>
              <div className="col-12 col-md-7  justify-content-center align-content-end">
                <div className="row my-2 text-muted">
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking From</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingFrom)}</div>
                  </div>
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking Till</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingTo)}</div>
                  </div>

                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Amount</strong>
                      {val.BookingAmount} KWD
                    </span>
                  </div>
                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Status</strong> {val.Status}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <small>
                      {" "}
                      Booked On: {globalService.formatDate(val.BookedOn)}
                    </small>
                  </div>
                  <div className="col text-right d-none">View Detail</div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default MyBooking;
