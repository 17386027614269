import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/bootstrap.css";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import $ from "jquery";
import Popper from "popper.js";
// import "./assets/js/custom";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
// BrowserRouter
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import appTheme from "./common/AppTheme";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <HashRouter>
    <MuiThemeProvider theme={appTheme}>
      <App />
    </MuiThemeProvider>
    <ToastContainer />
  </HashRouter>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <BrowserRouter>
//     <MuiThemeProvider theme={appTheme}>
//       <App />
//     </MuiThemeProvider>
//     <ToastContainer />
//   </BrowserRouter>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
