import * as React from "react";
import Header from "./../../components/header";
import Footer from "../../components/footer";
import { Route } from "react-router-dom";
import { AccountRoutes } from "./account-router";

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export interface AccountLayoutProps {}

const AccountLayout: React.SFC<AccountLayoutProps> = () => {
  return (
    <React.Fragment>
      <Header />
      <Route history={history} children={AccountRoutes} />
      <Footer />
    </React.Fragment>
  );
};

export default AccountLayout;
