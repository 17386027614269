import * as React from "react";

export interface AboutUsProps {}

const AboutUs: React.SFC<AboutUsProps> = () => {
  return (
    <React.Fragment>
      <div className="py-4 bg-primary">
        <div className="py-3"></div>
      </div>
      <section id="r-who-royal">
        <div className="r-who-royal">
          <div className="container">
            <div className="row clearfix">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-about-info-content">
                  <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-w pt-0">
                    <span>KNOW MORE ABOUT US</span>
                    <h2>
                      Who <b>Princess Cars</b> Are.
                    </h2>
                  </div>
                  <p>
                    We're different!! At the time of listing we do not place
                    hidden charges!! We will include ONLY the basics and the
                    necessary for you to rent and drive here in Kuwait.
                    <br />
                    <br />
                    We do not take advantage of you and your ignorance of the
                    subject so we do not share the policy of offering a low
                    price like others just to disguise and include insurance and
                    extras the moment you arrive at the counter.
                  </p>
                  <ul className="mb-0 pl-0">
                    <li>
                      <i className="fa fa-check-circle"></i> We working since
                      1980 with 4.000 customers
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i> All brand &amp;
                      type cars in our garage
                    </li>
                    <li>
                      <i className="fa fa-check-circle"></i> 1.000+ picking
                      locations around the world
                    </li>
                  </ul>
                  <a href="#" className="btn-primary">
                    VIEW ALL VEHICLES
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
