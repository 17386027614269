import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home";
import SearchResult from "./search-result";
import AboutUs from "./aboutus";
import ContactUs from "./contactus";
import SearchByType from "./searchbytype";
import PaymentLayout from "./payment/paymentLayout";

export const PageRoutes = (
  <Switch>
    <Route exact path="/site" component={Home} />
    <Route path="/site/search" component={SearchResult} />
    <Route exact path="/site/sbt/:type" component={SearchByType} />
    <Route path="/site/about" component={AboutUs} />
    <Route path="/site/contact" component={ContactUs} />
    <Redirect to="/not-found" />
  </Switch>
);
