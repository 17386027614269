import * as React from "react";
// import Fab from "@material-ui/core/Fab";
import whatsApp from "../assets/images/whatsapp-512.png";
import CallApp from "../assets/images/phone_PNG.png";
import galleryImg1 from "../assets/images/gallery-img01.jpg";
import galleryImg2 from "../assets/images/gallery-img02.jpg";
import galleryImg3 from "../assets/images/gallery-img03.jpg";
import galleryImg4 from "../assets/images/gallery-img04.jpg";
import galleryImg5 from "../assets/images/gallery-img05.jpg";

import footerLogo from "../assets/images/footer-logo.png";
export interface FooterProps {}

const Footer: React.SFC<FooterProps> = () => {
  return (
    <React.Fragment>
      <div id="r-gallery-part">
        <div className="r-gallery-part r-gallery-part-home py-0">
          <ul className="clearfix">
            <li>
              {/* <a
              // href="assets/images/gallery-img01.jpg"
              // data-rel="lightcase:myCollection"
              > */}
              <img src={galleryImg1} className="d-block img-fluid" alt="" />
              <div className="gallery-hover">
                <div className="gallery-text">
                  <div className="icon-gallery">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </li>
            <li>
              {/* <a
              // href="assets/images/gallery-img02.jpg"
              // data-rel="lightcase:myCollection"
              > */}
              <img src={galleryImg2} className="d-block img-fluid" alt="" />
              <div className="gallery-hover">
                <div className="gallery-text">
                  <div className="icon-gallery">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </li>
            <li>
              {/* <a
              // href="assets/images/gallery-img03.jpg"
              // data-rel="lightcase:myCollection"
              > */}
              <img src={galleryImg3} className="d-block img-fluid" alt="" />
              <div className="gallery-hover">
                <div className="gallery-text">
                  <div className="icon-gallery">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </li>
            <li>
              {/* <a
              // href="assets/images/gallery-img04.jpg"
              // data-rel="lightcase:myCollection"
              > */}
              <img src={galleryImg4} className="d-block img-fluid" alt="" />
              <div className="gallery-hover">
                <div className="gallery-text">
                  <div className="icon-gallery">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </li>
            <li>
              {/* <a
              // href="assets/images/gallery-img05.jpg"
              // data-rel="lightcase:myCollection"
              > */}
              <img src={galleryImg5} className="d-block img-fluid" alt="" />
              {/* <div className="gallery-hover"> */}
              <div className="gallery-text">
                <div className="icon-gallery">
                  <i className="fa fa-search" aria-hidden="true"></i>
                  {/* </div> */}
                </div>
              </div>
              {/* </a> */}
            </li>
          </ul>
        </div>
      </div>
      <footer>
        <div className="r-footer">
          <div className="container">
            <div className="row clearfix">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="r-footer-block">
                  <img src={footerLogo} className="d-block img-fluid" alt="" />
                  <p>
                    We're different!! At the time of listing we do not place
                    hidden charges!! We will include ONLY the basics and the
                    necessary for you to rent and drive here in Kuwait.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="r-footer-block">
                  <div className="r-footer-widget r-footer-phone">
                    <span>
                      <i className="fa fa-phone"></i> HOTLINE & WHATSAPP
                    </span>
                    <h5>
                      <a
                        className="text-primary"
                        href="https://api.whatsapp.com/send?phone=+96569622222"
                      >
                        +965 69622222
                      </a>
                    </h5>
                  </div>
                  <div className="r-footer-widget r-footer-nav">
                    <h6>USEFUL LINK</h6>
                    <nav>
                      <ul>
                        <li>
                          <a href="#">Private Policy</a>
                        </li>
                        <li>
                          <a href="#">Term & Conditions</a>
                        </li>
                        <li>
                          <a href="#">Copyright Notification</a>
                        </li>
                        <li>
                          <a href="#">Register for New Member</a>
                        </li>
                        <li>
                          <a href="#">Press Release</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-footer-block">
                  <div className="r-footer-widget r-footer-phone">
                    <span>
                      <i className="fa fa-envelope"></i> EMAIL
                    </span>
                    <h5 className="text-primary" style={{ fontSize: 20 }}>
                      info@princessq8.com
                    </h5>
                  </div>
                  <div className="r-footer-widget r-footer-nav">
                    <h6>Our Service Phone numbers</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="mb-0">Salmiya</p>
                        <h6>
                          <a href="tel:97938000">97938000</a> /{" "}
                          <a href="tel:25743555">25743555</a> /{" "}
                          <a href="tel:25732222">25732222</a>
                        </h6>
                        <br />
                        <p className="mb-0">Mangaf</p>
                        <h6>
                          <a href="tel:66444195">66444195 </a> /{" "}
                          <a href="tel:23734000">23734000</a> /{" "}
                          <a href="tel:23723331">23723331</a>
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-0">Ardiyah</p>
                        <h6>
                          <a href="tel:69612211">69612211</a> /{" "}
                          <a href="tel:94976152">94976152</a> /{" "}
                          <a href="tel:22274797">22274797</a>
                        </h6>
                        <br />
                        <p className="mb-0">West abo fatira</p>
                        <h6>
                          <a href="tel:69617711">69617711</a> /{" "}
                          <a href="tel:50227966">50227966</a> /{" "}
                          <a href="tel:22274798">22274798</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix r-footer-strip">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Copyrights © 2020 Princess Cars.
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                  <li>
                    <a href="https://www.youtube.com/channel/UCgyukha76J18it6l9A_ViRQ">
                      <i className="fa fa-youtube"></i>. <span>YouTube</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/rentcarprincess/">
                      <i className="fa fa-facebook"></i>. <span>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/princesscarq8">
                      <i className="fa fa-twitter"></i>.<span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a href="http://instagram.com/_u/princesscarq8/">
                      <i className="fa fa-instagram"></i>.<span>Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=+96569622222">
                      <i className="fa fa-whatsapp"></i>.<span>WhatsApp</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="r-to-top" className="r-to-top">
        <i className="fa fa-angle-up"></i>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 25,
          right: 5,
          zIndex: 9999
          // // height: "100vh",
          // top: 0,
          // right: 0
        }}
      >
        <a
          className="mr-1"
          href="https://api.whatsapp.com/send?phone=+96569622222"
          target="_blank"
        >
          <img src={whatsApp} className="whatAppWidth" style={{ width: 37 }} />
        </a>

        <a className="mr-1" href="tel:+96569622222" target="_blank">
          <img src={CallApp} className="whatAppWidth" style={{ width: 43 }} />
        </a>
      </div>
    </React.Fragment>
  );
};

export default Footer;
