import * as React from "react";
import {
  Box,
  TextField,
  AppBar,
  Tab,
  Tabs,
  Button,
  Typography
} from "@material-ui/core";
import { toast } from "react-toastify";
import globalService from "./../services/globalService";
import logo from "../assets/images/logo1.png";
import { Link } from "react-router-dom";

export interface AuthenticationProps {
  history;
}

export interface AuthenticationState {}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

class Authentication extends React.Component<
  AuthenticationProps,
  AuthenticationState
> {
  state = {
    currentTab: 0,
    data: {
      forgetUser: "",
      logUser: "",
      Username: "",
      Password: "",
      DeviceId: "",
      DeviceType: "Web",
      DeviceToken: "",
      FirstName: "",
      LastName: "",
      PhoneNumber: "",
      Email: "",
      password: "",
      confirmPassword: ""
    },
    isForgetPass: false
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ currentTab: newValue });
  };

  handleInputChange = ({ currentTarget: e }) => {
    let { data } = this.state;
    data[e.name] = e.value;
    this.setState({ data });
  };
  handleSubmit = evt => {
    evt.preventDefault();
    const { currentTarget: e } = evt;
    this.validate(e.form.name);
  };

  validate(form) {
    const { data } = this.state;

    if (form === "loginForm") {
      if (this.state.isForgetPass) {
        if (!data.forgetUser) {
          toast.error("Please enter the username");
          return false;
        }
      } else {
        if (!data.logUser) {
          toast.error("Please enter the username");
          return false;
        }
        if (!data.Password) {
          toast.error("Please enter the password");
          return false;
        }
      }
    } else {
      if (!data.Username) {
        toast.error("Please enter the username");
        return false;
      }
      if (!data.FirstName || !data.LastName) {
        toast.error("Please enter the Firstname and Lastname");
        return false;
      }
      if (!data.Email || !data.PhoneNumber) {
        toast.error("Please enter the Email and Phone");
        return false;
      } else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.Email)) {
        } else {
          toast.error("Please enter valid email Id");
          return false;
        }
      }
      if (!data.password || !data.confirmPassword) {
        toast.error("Please enter the Password and Confirm Password");
        return false;
      } else {
        if (data.password !== data.confirmPassword) {
          toast.error("Confirm password not matching");
          return false;
        }
      }
    }
    if (form === "loginForm") {
      if (this.state.isForgetPass) {
        this.forgetPassword();
      } else this.login();
    } else {
      this.register();
    }
  }

  doSubmit() {
    this.login();
    toast.success("Data added sucessfully");
  }

  async login() {
    let obj = {
      username: this.state.data.logUser,
      password: this.state.data.Password,
      DeviceId: "",
      DeviceType: "Web",
      DeviceToken: ""
    };

    const { data: resp } = await globalService.login(obj);
    if (resp.status === "success") {
      globalService.setUserSession(resp.response);
      console.log("Login success");
      this.props.history.push("/");
    } else toast.error(resp.response);
  }

  async forgetPassword() {
    let obj = {
      UserName: this.state.data.forgetUser
    };

    const { data: resp } = await globalService.forgetPassword(obj);
    if (resp.status === "success") {
      toast.success("Password has been sent to your email");
      console.log("Forget Password sent success");
      this.setState({ isForgetPass: false });
    } else toast.error(resp.response);
  }

  async register() {
    const data = this.state.data;
    let obj = {
      UserName: data.Username,
      Password: data.password,
      Email: data.Email,
      DeviceId: "",
      DeviceType: "Web",
      DeviceToken: "",
      CustomerInfo: {
        FirstName: data.FirstName,
        LastName: data.LastName,
        DOB: "",
        PhoneNumber: data.PhoneNumber,
        Photo: ""
      }
    };

    const { data: resp } = await globalService.customerRegister(obj);
    if (resp.status === "success") {
      if (resp.response === "User already Registered.") {
        toast.error("User already registered. Please login");
      } else {
        globalService.setUserSession(resp.response);
        console.log("Registered successfully");
        this.props.history.push("/");
      }
    } else toast.error(resp.response);
  }

  render() {
    const { currentTab } = this.state;
    return (
      <React.Fragment>
        <div className="auth-screen">
          <div className="inner">
            <div className="content">
              <div className="container">
                <div className="row align-items-center justify-content-center text-white">
                  <div className="col-12 col-md-6">
                    <Box
                      boxShadow={4}
                      style={{
                        background: "rgba(255,255,255,0.6)",
                        borderRadius: 3
                      }}
                    >
                      <div className="bg-primary text-center">
                        <Link to="/">
                          <img src={logo} alt="" />
                        </Link>
                      </div>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={currentTab}
                          onChange={this.handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          <Tab label="SIGN IN" {...a11yProps(0)} />
                          <Tab label="SIGN UP" {...a11yProps(1)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={currentTab} index={0}>
                        {this.renderLogin()}

                        <div className="mt-4  text-dark">
                          <Link className="text-dark" to="/">
                            Home
                          </Link>
                          <a
                            className="text-dark float-right"
                            href="https://admin.princessq8.com/#/"
                          >
                            Client Login
                          </a>
                        </div>
                      </TabPanel>
                      <TabPanel value={currentTab} index={1}>
                        {this.renderRegister()}
                        <div className="mt-4  text-dark">
                          <Link className="text-dark" to="/">
                            Home
                          </Link>

                          <a
                            className="text-dark float-right"
                            href="https://admin.princessq8.com/#/"
                          >
                            Client Login
                          </a>
                        </div>
                      </TabPanel>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderLogin() {
    const { logUser, Password, forgetUser } = this.state.data;
    const { isForgetPass } = this.state;
    return (
      <React.Fragment>
        <form autoComplete="off" name="loginForm" onSubmit={this.handleSubmit}>
          {!isForgetPass && (
            <React.Fragment>
              <div className="form-group">
                <TextField
                  value={logUser}
                  className="w-100"
                  id="outlined"
                  label="Username"
                  variant="standard"
                  name="logUser"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group">
                <TextField
                  value={Password}
                  className="w-100"
                  id="outlined-bas"
                  label="Password"
                  variant="standard"
                  type="password"
                  name="Password"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="text-right mb-3">
                <Button
                  variant="text"
                  onClick={() => this.setState({ isForgetPass: true })}
                  color="primary"
                >
                  Forget Password?
                </Button>
              </div>
            </React.Fragment>
          )}
          {isForgetPass && (
            <React.Fragment>
              <div className="text-primary">
                <p>Please enter your username</p>
              </div>

              <div className="form-group">
                <TextField
                  value={forgetUser}
                  className="w-100"
                  id="outlined"
                  label="Username"
                  variant="standard"
                  name="forgetUser"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="text-right mb-4">
                <Button
                  variant="text"
                  onClick={() => this.setState({ isForgetPass: false })}
                  color="primary"
                >
                  Remember Password?
                </Button>
              </div>
            </React.Fragment>
          )}
          <div className="form-group">
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              className="btn-block"
              // color="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderRegister() {
    const {
      Username,
      FirstName,
      LastName,
      Email,
      PhoneNumber,
      password,
      confirmPassword
    } = this.state.data;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit} name="regForm" autoComplete="off">
          <div className="row">
            <div className="col-12 form-group">
              <TextField
                name="Username"
                className="w-100"
                id="outlined"
                label="Username"
                variant="standard"
                value={Username}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="First name"
                variant="standard"
                name="FirstName"
                value={FirstName}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="Last name"
                variant="standard"
                name="LastName"
                value={LastName}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="Email"
                variant="standard"
                name="Email"
                value={Email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="Mobile"
                variant="standard"
                type="number"
                name="PhoneNumber"
                value={PhoneNumber}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="Password"
                variant="standard"
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-6 form-group">
              <TextField
                className="w-100"
                id="outlined"
                label="Confirm Password"
                variant="standard"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-12 form-group">
              <Button
                variant="contained"
                onSubmit={this.handleSubmit}
                onClick={this.handleSubmit}
                className="btn-block"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Authentication;
