import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Profile from "./profile";
import MyBooking from "./mybooking";
import NofiticationList from "./notificationList";
import BookingDetail from "./bookingDetail";

export const AccountRoutes = (
  <Switch>
    <Route exact path="/account" component={Profile} />
    <Route path="/account/mybooking" component={MyBooking} />
    <Route path="/account/profile" component={Profile} />
    <Route path="/account/notifications" component={NofiticationList} />
    <Route path="/account/booking-detail/:id" component={BookingDetail} />
    {/* <Route path="/v2" exact component={HomePageV2} />
    <Route path="/not-found" component={NotFound} />
    <Route path="/app" component={AdminLayout} />
    <Route path="/verify" component={VerifyPage} />
    <Route path="/item/add" component={AddAssetPage} />
    <Route path="/site" component={SiteTemplate} />
    <Route path="/redux-example" component={ReduxExample} /> */}

    {/* <Route path="/" render={() => <Redirect to="/not-found" />} /> */}
    <Redirect to="/not-found" />
  </Switch>
);
