import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import globalService from "../../services/globalService";
import { toast } from "react-toastify";
import { appString } from "../../common/appString";
import FetchingData from "../../components/FetchingData";
import NoDataFound from "../../components/NoDataFound";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface NofiticationListProps {
  history;
}

export interface NofiticationListState {}

class NofiticationList extends React.Component<
  NofiticationListProps,
  NofiticationListState
> {
  state = {
    currentTab: 0,
    upcomingList: [],
    historyList: [],
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    isLoading: false,

    notifyList: [],
  };

  componentDidMount() {
    // this.getUserDetail();
    this.getInit();
  }

  async getInit() {
    await this.getUserDetail();
    if (this.state.isLoggedIn) this.getNotification();
  }

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
      //   this.getBooking();
    } else this.setState({ isLoggedIn: false });
  }

  getNotification = async () => {
    const { userDetail } = this.state;

    this.setState({ isLoading: true });
    const { data: resp } = await globalService.getUserNotification(
      userDetail.UserInfo["Id"]
    );
    if (resp.status === "success") {
      this.setState({ notifyList: resp.response, isLoading: false });
    } else {
      this.setState({ isLoading: false });
      toast.error(resp.response);
    }
  };

  redirectTo = async (notifyId, bookingID) => {
    await globalService.getNoficationCountUpdate(
      this.state.userDetail.UserInfo["Id"],
      notifyId
    );
    this.props.history.push("/account/booking-detail/" + bookingID);
  };

  render() {
    const { currentTab, notifyList } = this.state;
    return (
      <React.Fragment>
        <div className="nav-ext d-none d-sm-block"></div>

        <div className="container mb-5">
          <Box
            boxShadow={4}
            style={{
              background: "rgba(255,255,255,0.6)",
              borderRadius: 3,
            }}
          >
            <div className="row mx-0">
              {notifyList.map((noti: any, idx) => {
                return (
                  <div key={idx} className="col-12 border p-3 noti-list">
                    <div className="media link-cursor">
                      <div className="media-body text-secondary">
                        <h5 className="notification-user text-primary">
                          {noti.Title}
                        </h5>
                        <p className="notification-msg">
                          {noti.NotificationDetails}
                        </p>
                        <span className="notification-time">
                          {noti.CreatedOn}
                        </span>
                        <span
                          className="view"
                          onClick={() =>
                            this.redirectTo(noti.Id, noti.BookingID)
                          }
                        >
                          <i className="fa fa-chevron-circle-right fa-2x text-primary"></i>
                        </span>

                        {!noti.IsViewed && (
                          <span className="new badge badge-primary">New</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Box>
        </div>
      </React.Fragment>
    );
  }

  renderBooking() {
    const { upcomingList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <FetchingData />
        ) : upcomingList.length === 0 ? (
          <NoDataFound />
        ) : (
          ""
        )}

        {upcomingList.map((val: any, idx) => {
          return (
            <div
              key={idx}
              className="row border bDetail bg-white my-2 p-1 shadow-sm"
            >
              <div className="col-12 bg-light">
                <h4 className="text-primary">
                  {val.Model + " " + val.Brand}{" "}
                  <span className="h6 text-secondary">{val.CarType}</span>{" "}
                </h4>
              </div>

              <div className="col-12 col-md-3" style={{ overflow: "hidden" }}>
                <div className="ng-star-inserted">
                  <img
                    alt=""
                    className="w-100"
                    src={appString.IMAGE_PATH + val.CarImages}
                  />
                </div>
              </div>
              <div className="col-12 col-md-7  justify-content-center align-content-end">
                <div className="row my-2 text-muted">
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking From</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingFrom)}</div>
                  </div>
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking Till</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingTo)}</div>
                  </div>

                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Amount</strong>
                      {val.BookingAmount} KWD
                    </span>
                  </div>
                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Status</strong> {val.Status}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <small>
                      {" "}
                      Booked On: {globalService.formatDate(val.BookedOn)}
                    </small>
                  </div>
                  <div className="col text-right">View Detail</div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  renderHistory() {
    const { historyList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <FetchingData />
        ) : historyList.length === 0 ? (
          <NoDataFound />
        ) : (
          ""
        )}

        {historyList.map((val: any, idx) => {
          return (
            <div
              key={idx}
              className="row border bDetail bg-white my-2 p-1 shadow-sm"
            >
              <div className="col-12 bg-light">
                <h4 className="text-primary">
                  {val.Model + " " + val.Brand}{" "}
                  <span className="h6 text-secondary">{val.CarType}</span>{" "}
                </h4>
              </div>

              <div className="col-12 col-md-3" style={{ overflow: "hidden" }}>
                <div className="ng-star-inserted">
                  <img
                    alt=""
                    className="w-100"
                    src={appString.IMAGE_PATH + val.CarImages}
                  />
                </div>
              </div>
              <div className="col-12 col-md-7  justify-content-center align-content-end">
                <div className="row my-2 text-muted">
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking From</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingFrom)}</div>
                  </div>
                  <div className="col-6">
                    <div>
                      <strong className="text-primary">Booking Till</strong>
                    </div>
                    <div>{globalService.formatDate(val.BookingTo)}</div>
                  </div>

                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Amount</strong>
                      {val.BookingAmount} KWD
                    </span>
                  </div>
                  <div className="col-6 my-2">
                    <span className="badge badge-primary mr-4">
                      <strong className="mr-2">Status</strong> {val.Status}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col">
                    <small>
                      {" "}
                      Booked On: {globalService.formatDate(val.BookedOn)}
                    </small>
                  </div>
                  <div className="col text-right">View Detail</div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default NofiticationList;
