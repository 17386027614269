export const appString = {
    API_ROOT: "https://api.princessq8.com/api/",
    "IMAGE_PATH": "https://api.princessq8.com/AppImages/",
    "ADMIN_IMAGE_PATH": "https://api.princessq8.com/AdminUploads/",
    "NOT_FOUND_IMG": "8bc9be3c-31e1-43ec-acec-8adce09de2ee.jpg",
    "API_ROOT00": "../api/v1/",
    "CMN_API_ROOT": "commonapi/v1/common/",
    "SPLITER": "~",
    "LS_REQ_FORM_DETAIL": "requestFormDetail",
    "USER_SESS": "user_auth",
    "ON_SEARCH": "onSearch",
    "WD": "wd",
    "WOD": "wod",
    "limoz": 'limoz'
}