// import { createMuiTheme } from 'material-ui/styles';
// import indigo from 'material-ui/colors/indigo';
// import pink from 'material-ui/colors/pink';
// import red from 'material-ui/colors/red';


import { createMuiTheme } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors'

export default createMuiTheme({
    palette: {
        // primary: {
        //     light: '#922a8d',
        //     main: '#922a8d',
        //     dark: '#fffff',
        //     contrastText: '#ffffff',
        // },
        primary: purple,
        secondary: {
            light: '#f1f1f1',
            main: '#b2b2b2',
            dark: '#898989',
            contrastText: '#898989',
        }// Indigo is probably a good match with pink
    }
});