import React from "react";
// import "./assets/css/plugins/font-awesome.min.css";
import "./assets/css/main.css";
import "./App.scss";
import { routes } from "./router";
import { withRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const App: React.FC = () => {
  return <Route history={history} children={routes} />;
};

export default withRouter(App as any);
