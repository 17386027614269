import * as React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import globalService from "../../services/globalService";
import { toast } from "react-toastify";
import { appString } from "../../common/appString";
import FetchingData from "../../components/FetchingData";
import NoDataFound from "../../components/NoDataFound";
import { format } from "date-fns";
import AppleStoreIcon from "../../assets/images/appleStore.png";
import googlePlayIcon from "../../assets/images/googlePlay.png";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface BookingDetailProps {}

export interface BookingDetailState {}

class PaymentDetail extends React.Component<
  BookingDetailProps,
  BookingDetailState
> {
  constructor(props) {
    super(props);
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const paymentId = params.get("paymentId");

    if (paymentId) {
      this.state.paymentId = paymentId;
    }
  }
  state = {
    bookingId: "",
    paymentId: "",
    paymentDetail: {} as any,
    isLoggedIn: false,
    isLoading: false,
    advPay: 0,
    showResult: false,
  };

  componentDidMount() {
    this.getInit();
  }

  async getInit() {
    this.getPaymentInvoice();
  }

  getPaymentInvoice = async () => {
    const { paymentId } = this.state;
    let bookingId = localStorage.getItem("lastBookId");
    this.setState({ isLoading: true, bookingId });

    let obj = localStorage.getItem("pAuth");
    let pAuth = {} as any;

    try {
      if (obj) pAuth = JSON.parse(obj);
      let body = {
        BookingId: bookingId,
        paymentId: paymentId,
        access_token: pAuth.access_token,
        token_type: pAuth.token_type,
      };
      const { data: resp } = await globalService.getPaymentInvoice(body);
      if (resp.status === "success") {
        this.setState({
          paymentDetail: resp.response,
          isLoading: false,
          showResult: true,
        });
      } else {
        this.setState({ isLoading: false, showResult: false });
        toast.error(resp.response);
      }
    } catch (e) {
      this.setState({ isLoading: false, showResult: false });
      console.log(e);
    }

    // let body1 = {
    //   BookingId: "187",
    //   paymentId: "100202020921942934",
    //   access_token:
    //     "kO5ZKJpLHkGD-UkNiDHoEIkuhPb2BScF6zC3XUaGVjaj7NgCLm-hB6eFiWSgi5VfDzaRtIe4padfNZX6fgfQFE9QOy8gFXiTQ7TUBhd6zOmekjEl4uqnrINaKXcZc0wK5T5VyOHc3F2ONkortE-8X52GteCP_CvAB5GmBwUHX3pZXAQWU44bcheE_mq81e4RgBBXuG0JpY5j2MGIhxfDLHQzFa8vvyaw2YHVpfz37epT6rMmyTmpuvwCRbAdTwvRTKdSfxlIAXK2cX6Go5bMHdeCH9YGeDu6o-CuTTRBwK-8G0J_JqoDQhegbdK1z1vBZd-tGVrPZU_6tiLDYQjvsN2yU2hTJXq9MxzxRlFYGAnouYrRkQMTNqMDc7bNwqF8eleFP2-4NZXVWVMal9lmScOC7Tko29Zfj9PehnZfW4PWo7eqr0DFZSHi7EfSGFm8kLZ-bxf50Wxo1eR8fD_MIGVbtlwBZy7f62gLMHS0jJpgo8kPzDDfzPo3NsIG-e2TLtkYeY2rekbIP0yLJ1py6zzDyTaQlPFXhu1ufM-yLfS-4FW6EbAxiDpmV3LqT5uK6W-H6yHNCwgWeE9iYPauc_GAaTl5tuEmkVUriS0ffcyC-drae-ndTXppYtvlTtMy4jZ1BQ",
    //   token_type: "bearer",
    // };

    // let body0 = {
    //   BookingId: "187",
    //   paymentId: "100202020865620422",
    //   access_token:
    //     "kO5ZKJpLHkGD-UkNiDHoEIkuhPb2BScF6zC3XUaGVjaj7NgCLm-hB6eFiWSgi5VfDzaRtIe4padfNZX6fgfQFE9QOy8gFXiTQ7TUBhd6zOmekjEl4uqnrINaKXcZc0wK5T5VyOHc3F2ONkortE-8X52GteCP_CvAB5GmBwUHX3pZXAQWU44bcheE_mq81e4RgBBXuG0JpY5j2MGIhxfDLHQzFa8vvyaw2YHVpfz37epT6rMmyTmpuvwCRbAdTwvRTKdSfxlIAXK2cX6Go5bMHdeCH9YGeDu6o-CuTTRBwK-8G0J_JqoDQhegbdK1z1vBZd-tGVrPZU_6tiLDYQjvsN2yU2hTJXq9MxzxRlFYGAnouYrRkQMTNqMDc7bNwqF8eleFP2-4NZXVWVMal9lmScOC7Tko29Zfj9PehnZfW4PWo7eqr0DFZSHi7EfSGFm8kLZ-bxf50Wxo1eR8fD_MIGVbtlwBZy7f62gLMHS0jJpgo8kPzDDfzPo3NsIG-e2TLtkYeY2rekbIP0yLJ1py6zzDyTaQlPFXhu1ufM-yLfS-4FW6EbAxiDpmV3LqT5uK6W-H6yHNCwgWeE9iYPauc_GAaTl5tuEmkVUriS0ffcyC-drae-ndTXppYtvlTtMy4jZ1BQ",
    //   token_type: "bearer",
    // };
  };

  render() {
    const {
      paymentDetail: pDetail,
      advPay,
      showResult,
      isLoading,
    } = this.state;

    return (
      <React.Fragment>
        <div className="nav-ext d-none d-sm-block"></div>

        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <Box
                boxShadow={4}
                style={{
                  background: "rgba(255,255,255,0.6)",
                  borderRadius: 3,
                }}
              >
                {pDetail.InvoiceId && this.renderDetail()}
                {(!showResult && !isLoading) ||
                  (pDetail.InvoiceId === null && (
                    <div className="my-5 py-5 text-center text-danger">
                      <h2>Something went wrong!!!</h2>
                      <h5>Please try again</h5>

                      <Link
                        className="btn btn-primary btn-sm my-2"
                        to={`/account/booking-detail/${this.state.bookingId}`}
                      >
                        Back
                      </Link>
                    </div>
                  ))}
              </Box>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDetail() {
    const { paymentDetail: pDetail } = this.state;

    return (
      <div className="row ml-0">
        <div className="col-12">
          <div className="text-center">
            {pDetail.Error && (
              <div>
                <h2 className="text-danger my-4">Payment Failure</h2>
              </div>
            )}
            {!pDetail.Error && (
              <div>
                <h2 className="text-success my-4">Payment Success</h2>
              </div>
            )}
          </div>

          <div className="table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Reference Id</td>
                  <td>{pDetail.ReferenceId}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Transaction Id</td>
                  <td>{pDetail.TransactionId}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Payment Amount</td>
                  <td>
                    <strong>
                      {pDetail.PaidCurrencyValue} {pDetail.PaidCurrency}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Transaction Date</td>
                  <td>{pDetail.TransactionDate}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Payment Gateway</td>
                  <td>{pDetail.PaymentGateway}</td>
                </tr>

                {pDetail.Error && (
                  <tr>
                    <td className="font-weight-bold">Transaction Error</td>
                    <td>{pDetail.Error}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="my-4 text-center">
            <Link
              className="btn btn-primary"
              to={`/account/booking-detail/${this.state.bookingId}`}
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentDetail;
