import * as React from "react";

import NoPic from "../../assets/images/no-pic.jpg";
import globalService from "../../services/globalService";
import { toast } from "react-toastify";

export interface ProfileProps {}

export interface ProfileState {}

class Profile extends React.Component<ProfileProps, ProfileState> {
  state = {
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {}
    },
    profileDetail: {} as any
  };
  componentDidMount() {
    this.getInit();
  }

  async getInit() {
    await this.getUserDetail();
    this.getProfileDetail();
  }
  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
    } else this.setState({ isLoggedIn: false });
  }

  async getProfileDetail() {
    this.setState({ isLoading: true });
    const { data: resp } = await globalService.getCustomerDetail(
      this.state.userDetail.UserInfo["Id"]
    );
    if (resp.status === "success") {
      this.setState({ profileDetail: resp.response[0], isLoading: false });
    } else {
      this.setState({ isLoading: false });
      toast.error(resp.response);
    }
  }

  render() {
    const { isLoggedIn, userDetail, profileDetail: pd } = this.state;
    const ud = userDetail.UserInfo;
    return (
      <React.Fragment>
        <div className="nav-ext d-none d-sm-block"></div>

        <div className="container">{pd.Id && this.renderProfile()}</div>
      </React.Fragment>
    );
  }

  renderProfile() {
    const { isLoggedIn, userDetail, profileDetail: ud } = this.state;
    // const ud = userDetail.UserInfo;
    return (
      <React.Fragment>
        <div className="shadow-sm">
          {isLoggedIn}
          <div className="row my-5">
            <div className="col-12 col-md-3">
              <div className="text-center my-2">
                <img src={NoPic} style={{ height: 190 }} alt="" />
              </div>
            </div>

            <div className="col-12 col-md bg-light py-2">
              <div className="row mx-0">
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="" className="text-secondary">
                    First Name
                  </label>
                  <h5>{ud.FirstName}</h5>
                </div>
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="" className="text-secondary">
                    Last Name
                  </label>
                  <h5>{ud.LastName}</h5>
                </div>
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="" className="text-secondary">
                    Username
                  </label>
                  <h5>{ud["UserName"]}</h5>
                </div>
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="" className="text-secondary">
                    Email ID
                  </label>
                  <h5>{userDetail["Email"]}</h5>
                </div>
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="" className="text-secondary">
                    Phone Number
                  </label>
                  <h5>{ud.PhoneNumber}</h5>
                  {/* <div>
                    <span className="badge badge-dark" style={{ fontSize: 20 }}>
                      {ud["UserType"]}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
