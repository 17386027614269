import * as React from "react";
import MSelect from "./MSelect";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { appString } from "../common/appString";
import globalService from "../services/globalService";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { format, addHours } from "date-fns";
// import { addHours } from "date-fns/esm";

export interface CarPriceFormProps {
  history: any;
  display;
  carDetail;
}

export interface CarPriceFormState {}

class CarPriceForm extends React.Component<
  CarPriceFormProps,
  CarPriceFormState
> {
  state = {
    carType: [
      { dName: "Car with Driver" },
      { dName: "Car without driver" },
      { dName: "Limousine" },
    ],
    bookingType: [
      { dName: "Pickup & Drop" },
      { dName: "Half Day" },
      { dName: "Full Day" },
    ],
    days: [
      { dName: "1" },
      { dName: "2" },
      { dName: "3" },
      { dName: "4" },
      { dName: "5" },
      { dName: "6" },
      { dName: "7" },
    ],
    pickupLocation: [],
    dropLocation: [],
    locList: [],
    hourList: [
      { dName: "1" },
      { dName: "2" },
      { dName: "3" },
      { dName: "4" },
      { dName: "5" },
    ],
    selType: this.props.display,
    selBooking: {} as any,
    selDay: {} as any,
    selPUTime: new Date(),
    selDOTime: new Date(),
    selPULoc: {} as any,
    selDOLoc: {} as any,
    selHour: {} as any,
    display: this.props.display,
    fromDate: new Date(),
    toDate: new Date(),
    carDetail: this.props.carDetail,
    totalPrice: 0,
    showBookingBtn: false,
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    search: {} as any,
  };

  componentDidMount() {
    this.getLocation();
    this.getUserDetail();
  }

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
    } else this.setState({ isLoggedIn: false });
  }

  async getLocation() {
    const { data: resp } = await globalService.getAllLocation();
    let data = [];
    if (resp.status === "success") {
      data = resp.response;
      for (let li of data) {
        li["dName"] = li["Name"];
      }
    }
    this.setState({ locList: data });
  }

  handleSelectChange = (e: any) => {
    this.setState({ [e.target.name.name]: e.target.value });
  };

  handleDateChange = (val: any, name: string) => {
    this.setState({ [name]: val, toDate: val });
  };

  onReset = () => {
    this.setState({
      selHour: "",
      fromDate: new Date(),
      toDate: new Date(),
      selPULoc: "",
      selDOLoc: "",
      carType: "",
      totalAmount: 0,
      showBookingBtn: false,
    });
  };

  onSearch = (e: any) => {
    e.preventDefault();

    const { carDetail: bc } = this.state;

    const data = this.state;

    let obj = {
      WithDriver: data.display === "cwod" ? false : true,
      CarType: data.display,
      FromDate: data.fromDate,
      ToDate: data.toDate,
      Hours: data.selHour ? Number(data.selHour["dName"]) : 1,
      BookingType: data.selBooking ? data.selBooking["dName"] : "",
      BookingDay: data.selDay ? data.selDay["dName"] : "",
      PickUpLocationId: data.selPULoc ? data.selPULoc["Id"] : "",
      DropLocationId: data.selDOLoc ? data.selDOLoc["Id"] : "",
      Size: 42,
      Index: 1,
    };

    if (data.display === "") {
      toast.error("Please select car type");
      return true;
    } else if (data.display === "limousine") {
      if (!data.selHour["dName"] || !data.fromDate) {
        toast.error("Please select hour and date");
        return true;
      }
      obj.ToDate.setDate(obj.FromDate.getDate());
      obj.ToDate.setHours(
        obj.FromDate.getHours() + Number(data.selHour["dName"])
      );
      obj.ToDate.setMinutes(obj.FromDate.getMinutes());
    } else if (data.display === "cwod") {
      if (!data.selPULoc["dName"] || !data.selDOLoc["dName"]) {
        toast.error("Please select the locations");
        return true;
      }
      obj.BookingDay = globalService.dateDiff(data.fromDate, data.toDate);
      obj.Hours = 8;
    } else if (data.display === "cwd") {
      if (!data.selBooking["dName"]) {
        toast.error("Please select the booking type");
        return true;
      } else if (data.selBooking["dName"] === "Full Day") {
        if (!data.selDay["dName"]) {
          toast.error("Please select the day ");
          return true;
        }
        obj.ToDate.setDate(
          obj.FromDate.getDate() +
            (data.selDay["dName"] ? Number(data.selDay["dName"]) : 0)
        );
        obj.ToDate.setHours(obj.FromDate.getHours());
        obj.ToDate.setMinutes(obj.FromDate.getMinutes());
      } else {
        obj.ToDate.setDate(obj.FromDate.getDate());
        obj.ToDate.setHours(obj.FromDate.getHours() + 1);
        obj.ToDate.setMinutes(obj.FromDate.getMinutes());
        obj.Hours = 1;
      }
      if (data.selBooking["dName"])
        obj.Hours =
          data.selBooking["dName"] === "Pickup & Drop"
            ? 1
            : data.selBooking["dName"] === "Half Day"
            ? 4
            : data.selBooking["dName"] === "Full Day"
            ? 8
            : 0;
    }

    let totalPrice =
      obj.Hours === 1
        ? bc.Pricings[0].Price1H
        : obj.Hours === 2
        ? bc.Pricings[0].Price2H
        : obj.Hours === 3
        ? bc.Pricings[0].Price3H
        : obj.Hours === 4
        ? bc.Pricings[0].Price4H
        : obj.Hours === 5
        ? bc.Pricings[0].Price8H
        : obj.Hours > 5
        ? obj.BookingDay * bc.Pricings[0].Price8H
        : 0;

    this.setState({ totalPrice, search: obj, showBookingBtn: true });
  };

  onBooking = async () => {
    const {
      search,
      isLoggedIn,
      carDetail: bc,
      userDetail: ud,
      totalPrice,
    } = this.state;
    if (isLoggedIn) {
      let postData = {
        WithDriver: search.WithDriver,
        BookingFrom: format(new Date(search.FromDate), "dd/MM/yyyy hh:mm a"),
        BookingTo: format(new Date(search.ToDate), "dd/MM/yyyy hh:mm a"),
        CarId: bc.Id,
        CarTypeId: bc.CarTypeId,
        UserId: ud.UserInfo["Id"],
        PricingId: bc.Pricings[0].Id,
        PickupId: search.PickUpLocationId,
        DropId: search.DropLocationId,
        BookingAmount: totalPrice,
        BookingPercentage: 0,
        Status: 0,
      };

      // console.log(postData);

      const { data: resp } = await globalService.bookCar(postData);
      if (resp.status === "success") {
        toast.success(
          "Your booking is success. Your will notification receive shortly"
        );
        this.props.history.push("/account/mybooking");
      }
    } else {
      this.props.history.push("/auth");
    }
  };

  render() {
    const {
      display,
      fromDate,
      toDate,
      bookingType,
      selBooking,
      days,
      hourList,
      locList,
      selDay,
      selPULoc,
      selDOLoc,
      selHour,
      totalPrice,
      showBookingBtn,
      isLoggedIn,
    } = this.state;
    return (
      <React.Fragment>
        <div className="row row-inputs">
          {display === "cwd" && (
            <React.Fragment>
              <div className="col-sm-12 py-2">
                <MSelect
                  listData={bookingType}
                  label="Select booking type"
                  onChange={this.handleSelectChange}
                  selected={selBooking}
                  name={"selBooking"}
                />
              </div>

              {selBooking["dName"] === "Full Day" && (
                <div className="col-sm-12 py-2">
                  <MSelect
                    listData={days}
                    label="Day"
                    onChange={this.handleSelectChange}
                    selected={selDay}
                    name={"selDay"}
                  />
                </div>
              )}
              <div className="col-sm-12 py-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    variant="inline"
                    label="Start Date"
                    className="w-100"
                    name="fromDate"
                    value={fromDate}
                    onChange={(val: any) =>
                      this.handleDateChange(val, "fromDate")
                    }
                    autoOk
                    disablePast
                    format="dd/MM/yyyy hh:mm a"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </React.Fragment>
          )}
          {display === "cwod" && (
            <React.Fragment>
              <div className="col-sm-6 py-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    variant="inline"
                    label="Picking Up Date"
                    name="selPUTime"
                    className="w-100"
                    value={fromDate}
                    onChange={(val: any) =>
                      this.handleDateChange(val, "fromDate")
                    }
                    autoOk
                    disablePast
                    format="dd/MM/yyyy hh:mm a"
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className="col-sm-6 py-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    className="w-100"
                    variant="inline"
                    label="Dropping off Date"
                    value={toDate}
                    name="selDOTime"
                    onChange={(val: any) =>
                      this.handleDateChange(val, "toDate")
                    }
                    autoOk
                    minDate={fromDate}
                    disablePast
                    format="dd/MM/yyyy hh:mm a"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-sm-6 py-2">
                <MSelect
                  listData={locList}
                  label="Pickup location"
                  onChange={this.handleSelectChange}
                  selected={selPULoc}
                  name="selPULoc"
                />
              </div>
              <div className="col-sm-6 py-2">
                <MSelect
                  listData={locList}
                  label="Dropping Location"
                  onChange={this.handleSelectChange}
                  selected={selDOLoc}
                  name="selDOLoc"
                />
              </div>
            </React.Fragment>
          )}
          {display === "limousine" && (
            <React.Fragment>
              <div className="col-sm-12 py-2">
                <MSelect
                  listData={hourList}
                  label="Hour"
                  onChange={this.handleSelectChange}
                  selected={selHour}
                  name="selHour"
                />
              </div>
              <div className="col-sm-12 py-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    variant="inline"
                    label="Start Date"
                    className="w-100"
                    value={fromDate}
                    name="fromDate"
                    onChange={(val: any) =>
                      this.handleDateChange(val, "fromDate")
                    }
                    autoOk
                    disablePast
                    format="dd/MM/yyyy hh:mm a"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </React.Fragment>
          )}

          {showBookingBtn && (
            <React.Fragment>
              <div className="my-3 col-12 text-center">
                <div>
                  {" "}
                  <strong>Total Price </strong>
                  <span className="badge badge-dark" style={{ fontSize: 20 }}>
                    {totalPrice}
                  </span>
                </div>
              </div>

              <div className="my-4 col-12 text-center">
                <Button color="secondary" onClick={this.onReset}>
                  Reset
                </Button>
                <Button color="primary" onClick={this.onBooking}>
                  {isLoggedIn ? "Book Now" : "Login to Book"}
                </Button>
              </div>
            </React.Fragment>
          )}

          {!showBookingBtn && (
            <div className="my-2 col-12 text-right">
              <Button color="primary" onClick={this.onSearch}>
                Check it
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CarPriceForm;
