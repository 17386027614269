import axios from "axios";
import { toast } from "react-toastify";
// import logger from "./logService";
// import authService from "./authService";

const LOADER = "pre-loader";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.response.use(
    resp => {
        loader(false);
        return resp;
    },
    error => {
        const expectedErr =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (!expectedErr) {
            // logger.log(error);
        } else {
            if (error.response.data) {
                toast.error(error.response.data.errorMessage);
            } else toast.error("error detecting.");
        }
        loader(false);

        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    req => {
        loader(true);
        const token = "";
        // authService.getUserToken();
        if (token) {
            req.headers["X-API-Key"] = token;
        } else {
            req.headers["X-API-Key"] = "";
        }

        return req;
    },
    error => {
        loader(false);
        return Promise.reject(error);
    }
);

function loader(enable: boolean) {
    if (enable) document.body.classList.add(LOADER);
    else document.body.classList.remove(LOADER);
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};