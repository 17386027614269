import * as React from "react";
import { Route } from "react-router-dom";
import { PageRoutes } from "./page-router";
import { createBrowserHistory } from "history";
import Header from "./../components/header";
import Footer from "../components/footer";

const history = createBrowserHistory();
const Layout: React.SFC = () => {
  return (
    <React.Fragment>
      <Header />
      <Route history={history} children={PageRoutes} />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
