import { Map, Marker, MapProps, GoogleApiWrapper } from "google-maps-react";
import * as React from "react";

interface Location {
  description: string;
  lat: any;
  lng: any;
  //   _geoloc: google.maps.LatLngLiteral;
  title: string;
}

type Props = MapProps & {
  //   hits: Location[];
  //   onLocationSelect: (location: Location) => void;
};

const mapStyles: google.maps.MapTypeStyle[] = [];
const centerKW: google.maps.LatLngLiteral = { lat: 29.1080117, lng: 48.130685 };

class MapContainer extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    fullscreenControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    initialCenter: centerKW,
    zoom: 9,
    maxZoom: 16,
    minZoom: 4,
    styles: mapStyles
  };

  state = {
    hits: []
  };

  componentDidMount() {
    this.getMapLocation();
  }

  async getMapLocation() {
    // const { data: resp } = await globalService.getMapLocation();
    // let data = [];
    // if (resp.status === "success") {
    //   data = resp.response;
    // }

    const data = [
      {
        title: "Mangaf",
        lat: "29.1080117000",
        lng: "48.1306850000",
        description: "Princess Car center"
      },
      {
        title: "Salimiya",
        lat: "29.3414268000",
        lng: "48.0932544000",
        description: "Princess Car center"
      },
      {
        title: "West of Abu Fatira Al-Herafia",
        lat: "29.1959228516",
        lng: "48.0530929565",
        description: "Princess Car Office"
      },
      {
        title: "Ardiya",
        lat: "29.2978229523",
        lng: "47.9128570557",
        description: "Princess Car Office Ardiya"
      }
    ];

    this.setState({ hits: data });
  }

  render() {
    const { bounds, ...props } = this.props;

    const hits = this.state.hits;

    return (
      <Map
        key=""
        {...props}
        bounds={bounds}
        onReady={(_, map) => {
          if (!map || !bounds) {
            return;
          }
          map.fitBounds(bounds);
        }}
      >
        {this.renderMarkers(...hits)}
      </Map>
    );
  }

  private renderMarkers(...hits: Location[]) {
    return hits.map((hit, idx) => {
      return (
        <Marker
          cursor="pointer"
          position={new google.maps.LatLng(hit.lat, hit.lng)}
          title={hit.title}
          key={idx}
        >
          {/* <InfoWindow>
            <p>Test</p>
          </InfoWindow> */}
        </Marker>
      );
    });
  }
}

// function validGeoloc(geoloc: google.maps.LatLngLiteral): boolean {
//   return geoloc && (geoloc.lat !== 0 || geoloc.lng !== 0);
// }

const connectGoogleMaps = GoogleApiWrapper({
  apiKey: "AIzaSyBK7lXLHQgaGdP3IvMPi1ej0B9JHUbcqB0",
  libraries: ["places", "geometry"]
});

const ConnectedMapContainer = connectGoogleMaps(MapContainer);

export default ConnectedMapContainer;

// "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/google-maps-react/google-maps-react-tests.tsx"
