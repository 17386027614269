import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../assets/images/footer-logo.png";
import globalService from "./../services/globalService";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
// import { MailIcon } from "'@material-ui/icons/Mail";
import logoLimoz from "../assets/images/limousine.svg";
import logoCWD from "../assets/images/cwd.svg";
import logoCWOD from "../assets/images/car.svg";

export interface HeaderProps {
  history;
}

export interface HeaderState {}

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    lastScrollY: "",
    fixedHeader: false,
    notifyCount: 0,
  };

  componentWillMount() {
    // When this component mounts, begin listening for scroll changes
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    // If this component is unmounted, stop listening
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let wScroll = window.screenTop;
    let halfWHeight = window.innerHeight / 2;

    if (wScroll > halfWHeight) {
      this.setState({ fixedHeader: true });
    } else {
      this.setState({ fixedHeader: false });
    }
  };

  componentDidMount() {
    this.getInit();
  }

  async getInit() {
    await this.getUserDetail();

    if (this.state.isLoggedIn)
      this.getNotification(this.state.userDetail.UserInfo["Id"]);
  }

  getNotification = async (userId) => {
    // const { data: resp } = await globalService.getUserNotification(userId);
    const { data: resp } = await globalService.getNoficationCountUpdate(
      userId,
      0
    );
    let data = [];
    if (resp.status === "success") {
      data = resp.response.IsUserCount;
    }
    this.setState({ notifyCount: data });
  };

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
    } else this.setState({ isLoggedIn: false });
  }

  handleLogout = () => {
    this.setState({ isLoggedIn: false });
    globalService.logoutUser();
  };

  handleCarTypeSearch = (type) => {
    switch (type) {
      case "cwd":
        this.props.history.push("/site/sbt/" + type);
        break;
      case "cwod":
        this.props.history.push("/site/sbt/" + type);
        break;
      case "limousine":
        this.props.history.push("/site/sbt/" + type);
        break;
      case "home":
        this.props.history.push("/");
        break;
      case "about":
        this.props.history.push("/site/" + type);
        break;
      case "contact":
        this.props.history.push("/site/" + type);
        break;
      case "auth":
        this.props.history.push("/" + type);
        break;
      case "notifications":
        this.props.history.push("/account/" + type);
        break;

      default:
        break;
    }

    // debugger;
    // this.props.history.push("/site/sbt/" + type);
    // return <Redirect to={"/site/sbt/" + type}></Redirect>;
    // this.props.history.push("/site/sbt/" + type);
  };

  render() {
    const { isLoggedIn, userDetail: ud, fixedHeader, notifyCount } = this.state;
    return (
      <header>
        <div className="text-right social-media-banner">
          <ul>
            <li>
              <a
                title="Youtube"
                href="https://www.youtube.com/channel/UCgyukha76J18it6l9A_ViRQ"
              >
                <i className="fa fa-youtube"></i> <span></span>
              </a>
            </li>
            <li>
              <a
                title="facebook"
                href="https://www.facebook.com/rentcarprincess/"
              >
                <i className="fa fa-facebook"></i> <span></span>
              </a>
            </li>
            <li>
              <a title="twitter" href="https://twitter.com/princesscarq8">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                title="instagram"
                href="http://instagram.com/_u/princesscarq8/"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                title="whatsapp"
                href="https://api.whatsapp.com/send?phone=+96569622222"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="r-header r-header-inner r-header-strip-02">
          <div
            className={`r-header-strip  ${
              fixedHeader ? " r-header-fixed" : ""
            }`}
          >
            <div className="container1 px-1 px-md-2 px-lg-3">
              {/* <Paper elevation={3}> */}
              <div className="row clearfix">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <div className="r-logo">
                    {/* <a href="#" className="d-inline-block"> */}
                    <Link to="/">
                      <img src={logo} className="img-fluid d-block" alt="" />
                    </Link>
                    {/* </a> */}
                  </div>
                  <a className="menu-icon">
                    {" "}
                    <i className="fa fa-bars"></i>{" "}
                  </a>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 pr-0">
                  <div className="r-header-action float-right mr-2"></div>
                  <div className="r-nav-section float-right">
                    <nav>
                      <ul>
                        <li className="text-center">
                          <Button
                            color="primary"
                            onClick={() => this.handleCarTypeSearch("home")}
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <i className="fa fa-home fa-2x"></i>
                              </div>
                              <div className="mt-2"> Home</div>
                            </div>
                          </Button>
                        </li>
                        <li className="text-center">
                          <Button
                            color="primary"
                            onClick={() => this.handleCarTypeSearch("cwd")}
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <img
                                  style={{ width: 40 }}
                                  src={logoCWD}
                                  alt=""
                                />
                              </div>
                              <div> Car With Driver</div>
                            </div>
                          </Button>
                        </li>
                        <li className="text-center">
                          <Button
                            color="primary"
                            onClick={() => this.handleCarTypeSearch("cwod")}
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <img
                                  style={{ width: 40 }}
                                  src={logoCWOD}
                                  alt=""
                                />
                              </div>
                              <div>Car Without Driver</div>
                            </div>
                          </Button>
                        </li>
                        <li className="my-auto text-center">
                          <Button
                            className="my-auto"
                            color="primary"
                            onClick={() =>
                              this.handleCarTypeSearch("limousine")
                            }
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <img
                                  style={{ width: 40 }}
                                  src={logoLimoz}
                                  alt=""
                                />
                              </div>
                              <div>Limousine</div>
                            </div>
                          </Button>
                        </li>
                        <li className="text-center">
                          <Button
                            color="primary"
                            onClick={() => this.handleCarTypeSearch("about")}
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <i className="fa fa-building fa-2x"></i>
                              </div>
                              <div className="mt-2"> ABOUT US</div>
                            </div>
                          </Button>
                        </li>
                        <li className="text-center">
                          <Button
                            color="primary"
                            onClick={() => this.handleCarTypeSearch("contact")}
                          >
                            <div style={{ display: "block" }}>
                              <div className="text-center">
                                <i className="fa fa-address-card fa-2x"></i>
                              </div>
                              <div className="mt-2"> CONTACT US</div>
                            </div>
                          </Button>
                        </li>
                        {/* <li>
                          <Link to="/site/about">ABOUT US</Link>
                        </li>
                        <li>
                          <Link to="/site/contact">CONTACT US</Link>
                        </li> */}

                        {!isLoggedIn && (
                          <li className="text-center">
                            <Button
                              color="secondary"
                              onClick={() => this.handleCarTypeSearch("auth")}
                            >
                              <div style={{ display: "block" }}>
                                <div className="text-center">
                                  <i className="fa fa-lock fa-2x"></i>
                                </div>
                                <div className="mt-2"> Login</div>
                              </div>
                            </Button>
                          </li>
                        )}
                        {isLoggedIn && (
                          <React.Fragment>
                            <li className="text-center">
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.handleCarTypeSearch("notifications")
                                }
                              >
                                <div style={{ display: "block" }}>
                                  <div className="text-center">
                                    {notifyCount > 0 ? (
                                      <Badge
                                        badgeContent={notifyCount}
                                        color="primary"
                                      >
                                        <i className="fa fa-bell fa-lg"></i>
                                      </Badge>
                                    ) : (
                                      <i className="fa fa-bell fa-lg"></i>
                                    )}
                                  </div>
                                  <div className="mt-2"> Notification</div>
                                </div>
                              </Button>
                            </li>
                            <li className="nav-item dropdown text-center">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                id="dropdown01"
                                data-toggle="dropdown"
                                aria-haspopup="false"
                                aria-expanded="true"
                              >
                                <i className="fa fa-user-circle"></i>
                                <span className="mx-1">Account</span>
                              </a>
                              <div
                                className="dropdown-menu dropdown-menu-right p-1"
                                aria-labelledby="dropdown01"
                              >
                                <div className="bg-primary text-white text-center rounded-top">
                                  {ud.UserInfo["UserName"]}{" "}
                                </div>
                                <div className="item">
                                  <Link to="/account/mybooking">
                                    My Booking
                                  </Link>
                                </div>
                                <div className="item">
                                  <Link to="/account/profile">Profile</Link>
                                </div>

                                <div
                                  className="item"
                                  onClick={this.handleLogout}
                                >
                                  <span>Logout</span>
                                  {/* <a onClick={() => globalService.logoutUser()}>
                                  Logout
                                </a> */}
                                  {/* <Link
                                  to=""
                                  
                                >
                                  
                                </Link> */}
                                </div>
                              </div>
                            </li>
                          </React.Fragment>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              {/* </Paper> */}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header as any);
