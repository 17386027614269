import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "./pages/layout";
import Authentication from "./pages/auth";
import AccountLayout from "./pages/account/accoutLayout";
import PaymentLayout from "./pages/payment/paymentLayout";

export const routes = (
  <Switch>
    <Route path="/site" component={Layout} />
    <Route path="/auth" component={Authentication} />
    <Route path="/account" component={AccountLayout} />
    <Route path="/payment" component={PaymentLayout} />

    <Route path="/" render={() => <Redirect to="/site" />} />
    <Redirect to="/not-found" />
  </Switch>
);
