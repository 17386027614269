import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PaymentDetail from "./paymentDetail";

export const PaymentRoutes = (
  <Switch>
    <Route exact path="/payment" render={() => <Redirect to="/account" />} />
    <Route path="/payment/success" component={PaymentDetail} />
    {/* <Route path="/account/profile" component={Profile} />
    <Route path="/account/notifications" component={NofiticationList} />
    <Route path="/account/booking-detail/:id" component={BookingDetail} /> */}

    {/* <Route path="/" render={() => <Redirect to="/not-found" />} /> */}
    <Redirect to="/not-found" />
  </Switch>
);
