import { appString } from "../common/appString";
import http from "./httpService";
import { format } from 'date-fns';


const API_ROOT = appString.API_ROOT;
const CMN_API_ROOT = getAppOrigin() + '/commonapi/v1/common/';


export function login(data: any) {
    return http.post(API_ROOT + 'user/login', data);
}

export function setUserSession(data) {
    sessionStorage.removeItem(appString.USER_SESS);
    sessionStorage.setItem(appString.USER_SESS, JSON.stringify(data));
}


interface userResp {
    UserInfo: any,
    Authorization: any,
    RoleId: string
}

export function getUserSession(): userResp | null {
    const user = sessionStorage.getItem(appString.USER_SESS);
    if (user) {
        const data = JSON.parse(user);
        let obj = {
            UserInfo: data.UserInfo,
            Authorization: data.Authorization,
            RoleId: data.RoleId
        }
        return obj;
    } else {
        return null;
    }
}

export function logoutUser() {
    sessionStorage.removeItem(appString.USER_SESS);
}

export function customerRegister(data: any) {
    return http.post(API_ROOT + 'customer/Register', data);
}

export function getCustomerDetail(id) {
    return http.get(API_ROOT + 'customer/getcustomer/' + id);
}

export function forgetPassword(data: any) {
    return http.post(API_ROOT + 'user/forgetpassword', data);
}

export function bookCar(data: any) {
    return http.post(API_ROOT + 'CarBooking/add', data);
}

export function getBookingByUser(data: any) {
    return http.post(API_ROOT + 'CarBooking/getbooking', data);
}

export function getUserNotification(userId) {
    return http.get(API_ROOT + 'carbooking/getUserIDPushNotification/50/1/' + userId);
}


export function getNoficationCountUpdate(userId, notifyId) {
    let body = { "UserId": userId, "Id": notifyId };
    return http.post(API_ROOT + 'carbooking/updateViewpushnotification', body);
}

export function getBookingDetailById(id) {
    return http.post(API_ROOT + 'carbooking/getbookingBookid', { Id: id });
}
export function getBookedAdvPay(id) {
    return http.get(API_ROOT + 'carbooking/pay/' + id);
}
export function getPaymentURLByBookID(id) {
    return http.get(API_ROOT + 'payment/PaymentWeb/' + id);
}
export function getPaymentInvoice(body) {
    // let body = {"BookingId": "","paymentId": "","access_token": "","token_type": ""}
    return http.post(API_ROOT + 'payment/PaymentStatus/', body);
}




export function getCarListBySearch(data: any) {
    return http.post(API_ROOT + 'car/search', data);
}

export function getLandingImg() {
    return http.get(API_ROOT + 'adminUploads/Get');
}

export function getAllBrand(isLimoz) {
    return isLimoz ? http.get(API_ROOT + 'brands/getall/1') : http.get(API_ROOT + 'brands/getall');
}

export function getAllCarType() {
    return http.get(API_ROOT + 'cartype/getall');
}

export function getAllLocation() {
    return http.get(API_ROOT + 'Location/getall');
}

export function getMapLocation() {
    return http.get(API_ROOT + 'Location/getall');
}

// https://api.princessq8.com/AppImages/9f66edac-48dc-41f8-9fab-7f3bb93c42c3.png
export function getAppOrigin() {
    return window.location.origin;
}

// export function getImg(img) {
//     let file = img !== '' && img ? img : NOT_FOUND_IMG;
//     return IMG_ROOT + file;
// }

export function generateSelectList(list: any[], label: string): any[] {
    for (let li of list) {
        li['dName'] = li[label];
    } return list;
}

export function getLocalDate(utcDate: any): any {
    let temp = new Date(utcDate);

    let dt = new Date(temp.getTime() - (temp.getTimezoneOffset() * 60 * 1000));
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(dt);
    // return new Date(temp.getTime() - (temp.getTimezoneOffset() * 60 * 1000)).toLocaleString();

}

export function dateDiff(from: any, to: any) {
    var date2 = new Date(to);
    var date1 = new Date(from);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
};


export function formatDate(myDate) {
    return format(new Date(myDate), "dd/MM/yyyy hh:mm a");
}

export function formatDate3(myDate) {
    let tempDate = new Date(myDate);
    tempDate.setHours(tempDate.getHours(), -3);
    return format(tempDate, "dd/MM/yyyy hh:mm a");
}



export type AccessType = "ADD" | "EDIT" | "DELETE";

export default {
    getCarListBySearch, getLandingImg, getAllBrand, getAllCarType, getAllLocation, getMapLocation,
    bookCar, getBookingByUser, getUserNotification, getNoficationCountUpdate, getBookingDetailById,
    getBookedAdvPay, getPaymentURLByBookID, getPaymentInvoice,
    customerRegister, getCustomerDetail, login, forgetPassword, logoutUser,
    getUserSession, setUserSession,
    API_ROOT, CMN_API_ROOT,
    generateSelectList, getLocalDate,
    dateDiff, formatDate, formatDate3
}