import * as React from "react";
// import { MapContainer } from "../components/mapContainer";
import ConnectedMapContainer from "./../components/mapContainer";

export interface ContactUsProps {}

const ContactUs: React.SFC<ContactUsProps> = () => {
  // const data = [
  //   {
  //     title: "Mangaf",
  //     lat: "29.1080117000",
  //     lng: "48.1306850000",
  //     description: "Princess Car center"
  //   },
  //   {
  //     title: "Salimiya",
  //     lat: "29.3414268000",
  //     lng: "48.0932544000",
  //     description: "Princess Car center"
  //   },
  //   {
  //     title: "West of Abu Fatira Al-Herafia",
  //     lat: "29.1959228516",
  //     lng: "48.0530929565",
  //     description: "Princess Car Office"
  //   },
  //   {
  //     title: "Ardiya",
  //     lat: "29.2978229523",
  //     lng: "47.9128570557",
  //     description: "Princess Car Office Ardiya"
  //   }
  // ];
  return (
    <React.Fragment>
      {/* <div className="py-4 bg-primary">
        <div className="py-3"></div>
      </div> */}
      <div style={{ height: "100vh" }}>
        <ConnectedMapContainer />
      </div>
      ;
    </React.Fragment>
  );
};

export default ContactUs;
