import * as React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarSearch from "../components/car-search";
import globalService from "../services/globalService";
import { appString } from "../common/appString";
// import bgCar from "../assets/images/bg-car.jpg";
import advantageIcon1 from "../assets/images/advantage-icon-1.png";
import advantageIcon2 from "../assets/images/advantage-icon-2.png";
import advantageIcon3 from "../assets/images/advantage-icon-3.png";

import happyCustWhite from "../assets/images/icon-happy-customer-white.png";
import carCountWhite from "../assets/images/icon-cars-count-white.png";

import carCenterWhite from "../assets/images/icon-car-center-white.png";

import totalKmWhite from "../assets/images/icon-total-km-white.png";
import googlePlay from "../assets/images/google-play-badge.svg";
import appleStore from "../assets/images/app-store-badge.svg";

import ConnectedMapContainer from "../components/mapContainer";

export interface HomeProps {
  history: any;
}

export interface HomeState {}

class Home extends React.Component<HomeProps, HomeState> {
  state = { data: [], IMG_PATH: appString.ADMIN_IMAGE_PATH, locList: [] };

  componentDidMount() {
    // this.getLandingImg();
    this.init();
  }

  async init() {
    await this.getLandingImg();
  }

  async getLandingImg() {
    const { data: resp } = await globalService.getLandingImg();
    let data = [];
    if (resp.status === "success") {
      data = resp.response;
    }
    this.setState({ data });
  }

  render() {
    // console.table(this.state.data);
    const { data, IMG_PATH } = this.state;
    return (
      <React.Fragment>
        <div className="r-wrapper">
          {/* header will come hiere */}
          <div className="r-slider r-slider-02">
            {this.state.data.length > 0 && (
              <OwlCarousel
                className="r-slider owl-theme"
                loop
                items={1}
                margin={10}
                nav
                autoplay={true}
              >
                {data.map((val: any, idx) => {
                  return (
                    <div key={idx} className="item r-slider-item">
                      <img
                        src={IMG_PATH + val.FileName}
                        className="img-fluid d-block m-auto"
                        alt=""
                      />
                      <div className="bg-dim"></div>
                      <div className="container">
                        <div className="r-slider-top-content">
                          <h1 className="animated fadeInDown">{val.Title}</h1>
                          <h4 className="animated fadeInLeft">
                            {val.Description}
                          </h4>
                          <a
                            href="#"
                            className="d-none btn btn-outlined animated fadeInUp"
                          >
                            {" "}
                            Reserve Now{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            )}
            {/* {!this.state.data.length && (
              <OwlCarousel
                className="r-slider owl-theme"
                loop
                items={1}
                margin={10}
                nav
                autoplay={true}
              >
                <div className="item r-slider-item">
                  <img
                    src={bgCar}
                    className="img-fluid d-block m-auto"
                    alt=""
                  />
                  <div className="bg-dim"></div>
                  <div className="container">
                    <div className="r-slider-top-content">
                      <h1 className="animated fadeInDown">G63 AMG</h1>
                      <h4 className="animated fadeInLeft">For Rent</h4>
                      <a
                        href="#"
                        className="d-none btn btn-outlined animated fadeInUp"
                      >
                        {" "}
                        Reserve Now{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </OwlCarousel> 
            )} */}
          </div>

          <CarSearch history={this.props.history} />

          <section id="r-advantages-part" className="r-advantages-part">
            <div className="r-advantage-main-part r-advantage-main-part-white">
              <div className="container clearfix">
                <div className="advantage-head">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <span>120+ CARS TYPE &amp; BRANDS</span>
                      <h2>
                        Princess Cars <b>Advantages.</b>
                      </h2>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <p className="r-sub-head-text">
                        We know the difference is in the details and that’s why
                        our car rental services, in the tourism and business
                        industry, stand out for their quality and good taste.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="r-advantages-box">
                      <div className="icon">
                        {" "}
                        <img src={advantageIcon1} alt="" />{" "}
                      </div>
                      <div className="head">24/7 Customer Online Support</div>
                      <div className="sub-text">Call us Anywhere Anytime</div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="r-advantages-box">
                      <div className="icon">
                        {" "}
                        <img src={advantageIcon2} alt="" />{" "}
                      </div>
                      <div className="head">Reservation Anytime You Wants</div>
                      <div className="sub-text">24/7 Online Reservation</div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="r-advantages-box">
                      <div className="icon">
                        {" "}
                        <img src={advantageIcon3} alt="" />{" "}
                      </div>
                      <div className="head">Lots of Picking Locations</div>
                      <div className="sub-text">250+ Locations</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-primary text-center text-white py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto text-white">
                  <h2 className="section-heading mb-4">
                    Try our App features!
                  </h2>
                  <p>
                    Our app is available on any mobile device! Download now to
                    get started!
                  </p>
                  <div className="badges my-3 ">
                    <a
                      className="badge-link m-2"
                      href="https://play.google.com/store/apps/details?id=com.app.princelessuser&hl=en"
                    >
                      <img src={googlePlay} alt="" style={{ width: 180 }} />
                    </a>
                    <a
                      className="badge-link m-2"
                      href="https://apps.apple.com/kw/app/princess-q8/id1447633788"
                    >
                      <img src={appleStore} alt="" style={{ width: 180 }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="r-who-royal">
            <div className="r-who-royal">
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="r-about-info-content">
                      <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-w pt-0">
                        <span>KNOW MORE ABOUT US</span>
                        <h2>
                          Who <b>Princess Cars</b> Are.
                        </h2>
                      </div>
                      <p>
                        We're different!! At the time of listing we do not place
                        hidden charges!! We will include ONLY the basics and the
                        necessary for you to rent and drive here in Kuwait.
                        <br />
                        <br />
                        We do not take advantage of you and your ignorance of
                        the subject so we do not share the policy of offering a
                        low price like others just to disguise and include
                        insurance and extras the moment you arrive at the
                        counter.
                      </p>
                      <ul className="mb-0 pl-0">
                        <li>
                          <i className="fa fa-check-circle"></i> We working
                          since 1980 with 4.000 customers
                        </li>
                        <li>
                          <i className="fa fa-check-circle"></i> All brand &amp;
                          type cars in our garage
                        </li>
                        <li>
                          <i className="fa fa-check-circle"></i> 1.000+ picking
                          locations around the world
                        </li>
                      </ul>
                      <a href="#" className="btn-primary d-none">
                        VIEW ALL VEHICLES
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="r-counter-section r-counter-with-bg m-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="r-counter-box">
                    <div className="r-counter-icon">
                      <img src={happyCustWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="r-counts" data-count="172700">
                      {/* <!-- 1.172.700 --> */}
                      <span className="r-count">0</span>
                    </div>
                    <span className="r-count-title"> HAPPY CUSTOMERS </span>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="r-counter-box">
                    <div className="r-counter-icon">
                      <img src={carCountWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="r-counts" data-count="2450">
                      {/* <!-- 2.450 --> */}
                      <span className="r-count">0</span>
                    </div>
                    <span className="r-count-title"> CARS IN GARAGE </span>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="r-counter-box">
                    <div className="r-counter-icon">
                      <img src={totalKmWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="r-counts" data-count="1211100">
                      {/* <!-- 1.211.100 --> */}
                      <span className="r-count">0</span>
                    </div>
                    <span className="r-count-title"> TOTAL KILOMETER/MIL </span>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="r-counter-box">
                    <div className="r-counter-icon">
                      <img src={carCenterWhite} alt="" className="img-fluid" />
                    </div>
                    <div className="r-counts" data-count="47250">
                      {/* <!-- 47.250 --> */}
                      <span className="r-count">0</span>
                    </div>
                    <span className="r-count-title">
                      {" "}
                      CAR CENTER SOLUTIONS{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="r-faq-section">
            <div className="r-faq-section r-faq-white-bg">
              <div className="container">
                <div className="row v-align-center r-faq-header-wrapper">
                  <div className="col-md-8 col-sm-12">
                    <div className="r-faq-header">
                      <span>FIND YOUR SERVICE HERE</span>
                      <h2>
                        Different<strong> Services!!</strong>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row r-faq-accordion-wrapper">
                  <div className="col-lg-6 col-md-12">
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Luxury in our why
                      </div>
                      <div className="r-accordion-body">
                        <p>
                          Deal with the best choice you have for car rent in
                          Kuwait, with us you have the best cars with the best
                          management and the best services.
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Transportation service
                      </div>
                      <div className="r-accordion-body">
                        <p>
                          yours satisfy is one of the most precious assets we
                          have. We help you to connects you to all areas of
                          life, including work, family & travel.With so much
                          riding on the move, it has to be done right.
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Limousine service
                      </div>
                      <div className="r-accordion-body">
                        <p>
                          Not only is Kuwait City the capital and largest city
                          of Kuwait, it’s also a major political, financial, and
                          commercial hub in the region. For travelers visiting
                          Kuwait City on vacation, it’s important to be able to
                          get around with the style, comfort, and reliability
                          that only a private car service can offer. That’s
                          where PRINCESS comes in, to make your journey a
                          pleasure no matter where or when you may be traveling.
                          Book the ideal limousine service in Kuwait City today!
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Princess App
                      </div>
                      <div className="r-accordion-body d-none">
                        <p>
                          New Look. New Experience. Take Control. Looking to
                          choose your own vehicle upon arrival? How about skip
                          the counter at pickup? Do all of this and more right
                          from your phone using our App! Renting a car has never
                          been easier.
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Deliver your car
                      </div>
                      <div className="r-accordion-body">
                        <p>
                          We offer the best service with the easy and best with
                          specific details about time and location delivered to…
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                    <div className="r-accordion">
                      <div className="r-accordion-heading">
                        <span className="r-accordion-toggle">
                          <i className="fa-arrow-circle-down fa"></i>
                        </span>{" "}
                        Short, Mid. & long run
                      </div>
                      <div className="r-accordion-body">
                        <p>
                          You can rent any car you choose from us, long, mid &
                          long run rent contracts with the best services for
                          every kind of our contracts.
                        </p>
                      </div>
                    </div>
                    {/* <!-- /r-accordion --> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <a href="#" className="btn-primary d-none">
                      {" "}
                      MORE SERVICES
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="content-area" style={{ height: "100vh" }}>
            <div className="google-map r-map-large">
              <div id="map-canvas">
                <ConnectedMapContainer />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
