import * as React from "react";

export interface NoDataFoundProps {}

const NoDataFound: React.SFC<NoDataFoundProps> = () => {
  return (
    <div className="bg-light rounded p-3 my-5 text-center">
      <h4>No Data found</h4>
    </div>
  );
};

export default NoDataFound;
