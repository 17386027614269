import * as React from "react";
import globalService from "../services/globalService";
import { appString } from "../common/appString";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { format } from "date-fns";
import FetchingData from "../components/FetchingData";
import NoDataFound from "../components/NoDataFound";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CarPriceForm from "../components/car-price-search";

export interface SearchByTypeProps {
  history;
}

export interface SearchByTypeState {}

class SearchByType extends React.Component<
  SearchByTypeProps,
  SearchByTypeState
> {
  constructor(props) {
    super(props);
    const { match } = props;

    const type = match.params.type;

    if (type) {
      this.state.searchType = type;
      this.state.search.WithDriver = type === "cwod" ? false : true;
      // toast.success(type);
    }
  }

  state = {
    searchType: "",
    brandList: [],
    carTypeList: [],
    carList: [],
    imgPath: appString.IMAGE_PATH,
    search: {
      WithDriver: true,
      carType: "",
      FromDate: new Date(),
      ToDate: new Date(),
      CarTypeId: [],
      BrandId: [],
      Hours: 1,
      BookingType: "PICKUP",
      BookingDay: 1,
      PickUpLocationId: "",
      DropLocationId: "",
      Size: 42,
      Index: 1,
    },
    brandIds: [] as any,
    typeIds: [] as any,
    isOpen: false,
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    bookingCar: {} as any,
    isLoading: false,
  };

  componentDidMount() {
    this.init();
    this.getUserDetail();
  }

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
    } else this.setState({ isLoggedIn: false });
  }

  async init() {
    await this.getcarTypeList();
    // await this.getSearch();
    await this.getSearch(this.state.searchType);
    await this.getCarList();
    // await this.getBrands();
  }

  componentWillReceiveProps(nextProps) {
    // debugger;
    this.getSearch(nextProps.match.params.type);
    // this.getBrands();
  }

  getSearch(searchType) {
    let { search, carTypeList, typeIds } = this.state;
    search.CarTypeId = [];
    search.carType = searchType;
    if (searchType === "limousine") {
      for (let ct of carTypeList) {
        if (ct["Isflag"]) search.CarTypeId.push(ct["Id"]);
      }
    } else {
    }

    search.WithDriver = searchType === "cwod" ? false : true;
    typeIds = search.CarTypeId;
    this.setState({ searchType, search, typeIds });
    this.getCarList();
  }

  getSearch1OLD() {
    // debugger;
    const data = sessionStorage.getItem(appString.ON_SEARCH);
    let { search, carTypeList, typeIds } = this.state;
    let temp;
    if (data) {
      temp = JSON.parse(data);

      // CarType: data.display,
      if (temp["CarType"] === appString.limoz) {
        search.carType = appString.limoz;
        search.CarTypeId = [];

        for (let ct of carTypeList) {
          if (ct["Isflag"]) search.CarTypeId.push(ct["Id"]);
        }
      }

      search.WithDriver = temp["WithDriver"];
      search.carType = temp["CarType"];
      search.FromDate = temp["FromDate"];
      search.ToDate = temp["ToDate"];
      search.BrandId = [];
      search.Size = temp["Size"];
      search.Index = temp["Index"];
      search.Hours = temp["Hours"];
      search.BookingDay = temp["BookingDay"];
      search.BookingType = temp["BookingType"];
      search.PickUpLocationId = temp["PickUpLocationId"];
      search.DropLocationId = temp["DropLocationId"];

      typeIds = search.CarTypeId;
      this.setState({ search, typeIds });
    }
  }

  async getBrands() {
    const { data: resp } = await globalService.getAllBrand(
      this.state.searchType === "limousine"
    );
    let brandList = [];
    if (resp.status === "success") {
      brandList = resp.response;
    }
    this.setState({ brandList });
  }
  async getCarList() {
    const { search } = this.state;
    this.setState({ isLoading: true });

    let body = {
      WithDriver: search.carType === "cwod" ? false : true,
      FromDate: search.FromDate,
      ToDate: search.ToDate,
      CarTypeId: search.CarTypeId,
      BrandId: search.BrandId,
      Size: 42,
      Index: 1,
    };
    const { data: resp } = await globalService.getCarListBySearch(body);
    let carList = [];
    if (resp.status === "success") {
      carList = resp.response;
    }
    this.setState({ carList, isLoading: false });
    this.getBrands();
  }
  async getcarTypeList() {
    const { data: resp } = await globalService.getAllCarType();
    let carTypeList = [];
    if (resp.status === "success") {
      carTypeList = resp.response;
    }
    this.setState({ carTypeList, isLoading: false });
  }

  onChangeFilter = (e: any, field: string, val: any) => {
    let { brandIds, typeIds, search } = this.state;
    if (field === "type") {
      if (e.target.checked) {
        typeIds.push(val);
      } else {
        let tempType = typeIds.filter((data: any) => data !== val);
        typeIds = tempType;
      }
      this.setState({ typeIds });
    } else if (field === "brand") {
      if (e.target.checked) {
        brandIds.push(val);
      } else {
        brandIds = brandIds.filter((data: any) => data !== val);
      }
      this.setState({ brandIds });
    }

    search.BrandId = brandIds;
    search.CarTypeId = typeIds;
    this.setState({ search });
    this.getCarList();
  };

  handleCarRenatal = (car) => {
    this.setState({ bookingCar: car, isOpen: true });
  };

  handleDateChange = (val, name) => {};

  carBooking = async () => {
    const { search, isLoggedIn, bookingCar: bc, userDetail: ud } = this.state;
    if (isLoggedIn) {
      this.setState({ isOpen: false });
      let postData = {
        WithDriver: search.WithDriver,
        BookingFrom: format(new Date(search.FromDate), "dd/MM/yyyy hh:mm a"),
        BookingTo: format(new Date(search.ToDate), "dd/MM/yyyy hh:mm a"),
        CarId: bc.Id,
        CarTypeId: bc.CarTypeId,
        UserId: ud.UserInfo["Id"],
        PricingId: bc.Pricings[0].Id,
        PickupId: search.PickUpLocationId,
        DropId: search.DropLocationId,
        BookingAmount: bc.Pricings[0].Price8H,
        BookingPercentage: 0,
        Status: 0,
      };

      const { data: resp } = await globalService.bookCar(postData);
      if (resp.status === "success") {
        toast.success(
          "Your booking is success. Your will notification receive shortly"
        );
        this.props.history.push("/account/mybooking");
      }
    } else {
      this.props.history.push("/auth");
    }
  };

  render() {
    const {
      brandList: bl,
      carTypeList: ct,
      carList: cars,
      imgPath,
      search,
      isOpen,
      isLoggedIn,
      bookingCar: bc,
      isLoading,
    } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={() => this.setState({ isOpen: !isOpen })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">
            {!isLoggedIn ? "Check Price" : "Check Price"}
          </DialogTitle>
          <DialogContent>
            <CarPriceForm
              history={this.props.history}
              display={this.state.searchType}
              carDetail={this.state.bookingCar}
            ></CarPriceForm>

            {/* {!isLoggedIn && (
              <DialogContentText id="alert-dialog-description">
                Please login to continue the booking
              </DialogContentText>
            )}

            {isLoggedIn && (
              <DialogContentText id="alert-dialog-description">
                Are you sure do you want book{" "}
                <strong className="text-primary">
                  {" "}
                  {bc.Name} - {bc.Brand}{" "}
                </strong>{" "}
                car?
              </DialogContentText>
            )} */}

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                variant="inline"
                label="Start Date"
                className="w-100"
                name="fromDate"
                value={new Date()}
                // value={fromDate}
                onChange={(val: any) => this.handleDateChange(val, "fromDate")}
                autoOk
                disablePast
                format="dd/MM/yyyy hh:mm a"
              />
            </MuiPickersUtilsProvider> */}
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={() => this.setState({ isOpen: !isOpen })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.carBooking} color="primary" autoFocus>
              {isLoggedIn ? "Book Now" : "Login to Book"}
            </Button>
          </DialogActions> */}
        </Dialog>

        <div className="nav-ext d-none d-sm-block"></div>

        <div className="container car-search">
          <div className="row">
            <div className="col-md-3">
              {search.carType !== "limousine" && (
                <div className="filter-box">
                  <div className="filter-title">Car Type</div>
                  <div className="filter-content">
                    <ul className="unstyled centered">
                      {ct.map((val: any, idx) => {
                        if (!val.Isflag)
                          return (
                            <li key={idx}>
                              <input
                                className="styled-checkbox"
                                id={"ct" + idx}
                                type="checkbox"
                                value="value1"
                                onClick={(e) =>
                                  this.onChangeFilter(e, "type", val.Id)
                                }
                              />
                              <label htmlFor={"ct" + idx}>{val.Name}</label>
                            </li>
                          );
                        else {
                          return "";
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}
              <div className="filter-box">
                <div className="filter-title">Brand</div>
                <div className="filter-content">
                  <ul className="unstyled centered">
                    {bl.map((val: any, idx) => {
                      return (
                        <li key={idx}>
                          <input
                            className="styled-checkbox"
                            id={"bl" + idx}
                            type="checkbox"
                            value="value1"
                            onClick={(e) =>
                              this.onChangeFilter(e, "brand", val.Id)
                            }
                          />
                          <label htmlFor={"bl" + idx}>{val.Name}</label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-12">
                  {isLoading ? (
                    <FetchingData />
                  ) : cars.length === 0 ? (
                    <NoDataFound />
                  ) : (
                    ""
                  )}
                </div>

                {cars.map((val: any, idx) => {
                  return (
                    <div key={idx} className="col-md-4">
                      <div className="">
                        <div className="r-best-offer-list">
                          <div
                            className="r-best-offer-single"
                            style={{ height: 320 }}
                          >
                            <div className="r-best-offer-in box-shadow">
                              <img
                                src={imgPath + val.BrandImage}
                                className="brand-img"
                                alt=""
                              />
                              <div className="r-offer-img">
                                <img
                                  src={imgPath + val.Images[0].Name}
                                  className="img-fluid d-block m-auto"
                                  alt=""
                                />
                                <a href="car-booking.html" className="d-none">
                                  <div className="r-offer-img-over d-none">
                                    <i className="fa fa-search"></i>
                                  </div>
                                </a>
                              </div>
                              <div
                                className="r-best-offer-content"
                                style={{ height: 120 }}
                              >
                                <a href="#">
                                  <b>{val.Name} </b> {val.CarType}
                                </a>

                                {/* {(search.carType === appString.WOD ||
                                  search.BookingType === "Full Day") && (
                                  <React.Fragment>
                                    {search.Hours === 1 && (
                                      <p className="my-2">
                                        Price
                                        <b className="mx-1">
                                          {val.Pricings[0].Price1H}
                                        </b>{" "}
                                        KWD /Day * {search.BookingDay} Day
                                      </p>
                                    )}
                                    {search.Hours === 2 && (
                                      <p className="my-2">
                                        Price
                                        <b className="mx-1">
                                          {val.Pricings[0].Price2H}
                                        </b>{" "}
                                        KWD /Day * {search.BookingDay} Day
                                      </p>
                                    )}
                                    {search.Hours === 3 && (
                                      <p className="my-2">
                                        Price
                                        <b className="mx-1">
                                          {val.Pricings[0].Price3H}
                                        </b>{" "}
                                        KWD /Day * {search.BookingDay} Day
                                      </p>
                                    )}
                                    {search.Hours === 4 && (
                                      <p className="my-2">
                                        Price
                                        <b className="mx-1">
                                          {val.Pricings[0].Price4H}
                                        </b>{" "}
                                        KWD /Day * {search.BookingDay} Day
                                      </p>
                                    )}
                                    {search.Hours > 1 && (
                                      <p className="my-2">
                                        Price
                                        <b className="mx-1">
                                          {val.Pricings[0].Price8H}
                                        </b>{" "}
                                        KWD /Day * {search.BookingDay} Day
                                      </p>
                                    )}
                                  </React.Fragment>
                                )} */}

                                {/* <p className="mb-2">
                                  Total Price{" "}
                                  <strong>
                                    {search.Hours === 1
                                      ? val.Pricings[0].Price1H
                                      : search.Hours === 2
                                      ? val.Pricings[0].Price2H
                                      : search.Hours === 3
                                      ? val.Pricings[0].Price3H
                                      : search.Hours === 4
                                      ? val.Pricings[0].Price4H
                                      : search.Hours === 5
                                      ? val.Pricings[0].Price8H
                                      : search.Hours > 5
                                      ? search.BookingDay *
                                        val.Pricings[0].Price8H
                                      : 0}
                                  </strong>{" "}
                                  KWD{" "}
                                </p> */}

                                <ul className="pl-0 mb-0">
                                  {val.CarAttributes.map(
                                    (ca: any, idxs: any) => {
                                      if (ca.AttributeId == "1") {
                                        return (
                                          <li key={idxs}>
                                            <i className="fa fa-group"></i>
                                            <span>{ca.Value}</span>
                                          </li>
                                        );
                                      } else if (ca.AttributeId == "2") {
                                        return (
                                          <li key={idxs}>
                                            <i className="fa fa-cogs"></i>
                                            <span>{ca.Value}</span>
                                          </li>
                                        );
                                      } else if (ca.AttributeId == "3") {
                                        return (
                                          <li key={idxs}>
                                            <i className="fa fa-car"></i>
                                            <span>{ca.Value}</span>
                                          </li>
                                        );
                                      } else if (ca.AttributeId == "4") {
                                        return (
                                          <li key={idxs}>
                                            <i className="fa fa-suitcase"></i>
                                            <span>{ca.Value}</span>
                                          </li>
                                        );
                                      } else if (
                                        ca.AttributeId == "5" &&
                                        idxs < 4
                                      ) {
                                        return (
                                          <li key={idxs}>
                                            <i className="fa fa-beer"></i>
                                            <span>{ca.Value}</span>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                </ul>
                              </div>
                              <div
                                className="r-offer-rewst-this"
                                onClick={() => this.handleCarRenatal(val)}
                              >
                                <span className="text-uppercase">
                                  View Price
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderForm() {
    return <React.Fragment>fdsafsd</React.Fragment>;
  }
}

export default SearchByType;
