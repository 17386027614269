import * as React from "react";

export interface FetchingDataProps {}

const FetchingData: React.SFC<FetchingDataProps> = () => {
  return (
    <div className="bg-light rounded p-3 my-5 text-center">
      <h4>Loading...</h4>
    </div>
  );
};

export default FetchingData;
