import * as React from "react";
import MSelect from "./MSelect";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { appString } from "../common/appString";
import globalService from "../services/globalService";
import footerFormIcon from "../assets/images/footer-form-icon.png";
import { toast } from "react-toastify";

export interface CarSearchProps {
  history: any;
}

export interface CarSearchState {}

class CarSearch extends React.Component<CarSearchProps, CarSearchState> {
  state = {
    carType: [
      { dName: "Car with Driver" },
      { dName: "Car without driver" },
      { dName: "Limousine" },
    ],
    bookingType: [
      { dName: "Pickup & Drop" },
      { dName: "Half Day" },
      { dName: "Full Day" },
    ],
    days: [
      { dName: "1" },
      { dName: "2" },
      { dName: "3" },
      { dName: "4" },
      { dName: "5" },
      { dName: "6" },
      { dName: "7" },
    ],
    pickupLocation: [],
    dropLocation: [],
    locList: [],
    hourList: [
      { dName: "1" },
      { dName: "2" },
      { dName: "3" },
      { dName: "4" },
      { dName: "5" },
    ],
    selType: {} as any,
    selBooking: {} as any,
    selDay: {} as any,
    selPUTime: new Date(),
    selDOTime: new Date(),
    selPULoc: {} as any,
    selDOLoc: {} as any,
    selHour: {} as any,
    display: "",
    fromDate: new Date(),
    toDate: new Date(),
  };

  componentDidMount() {
    this.getLocation();
  }

  async getLocation() {
    const { data: resp } = await globalService.getAllLocation();
    let data = [];
    if (resp.status === "success") {
      data = resp.response;
      for (let li of data) {
        li["dName"] = li["Name"];
      }
    }
    this.setState({ locList: data });
  }

  onChangeCarType = (e: any) => {
    let selType = e.target.value as any;
    let display = "";
    // debugger;
    var toDate = new Date();
    var fromDate = new Date();
    if (selType.dName === "Car with Driver") {
      display = "wd";
    } else if (selType.dName === "Car without driver") {
      display = "wod";
      fromDate = new Date();
      toDate.setDate(toDate.getDate() + 1);
    } else if (selType.dName === "Limousine") {
      display = "limoz";
    } else {
      display = "";
    }
    this.setState({ selType, display, fromDate, toDate });
  };

  handleSelectChange = (e: any) => {
    this.setState({ [e.target.name.name]: e.target.value });
  };

  handleDateChange = (val: any, name: string) => {
    var selDate = new Date(val);
    selDate.setDate(selDate.getDate() + 1);
    this.setState({ [name]: val, toDate: name === "fromDate" ? selDate : val });
  };

  onSearch = (e: any) => {
    e.preventDefault();

    const data = this.state;

    let obj = {
      WithDriver: data.display === appString.WOD ? false : true,
      CarType: data.display,
      FromDate: data.fromDate,
      ToDate: data.toDate,
      Hours: data.selHour ? Number(data.selHour["dName"]) : 1,
      BookingType: data.selBooking ? data.selBooking["dName"] : "",
      BookingDay: data.selDay ? data.selDay["dName"] : "",
      PickUpLocationId: data.selPULoc ? data.selPULoc["Id"] : "",
      DropLocationId: data.selDOLoc ? data.selDOLoc["Id"] : "",
      Size: 42,
      Index: 1,
    };

    if (data.display === "") {
      toast.error("Please select car type");
      return true;
    } else if (data.display === "limoz") {
      if (!data.selHour["dName"] || !data.fromDate) {
        toast.error("Please select hour and date");
        return true;
      }
      obj.ToDate.setDate(obj.FromDate.getDate());
      obj.ToDate.setHours(
        obj.FromDate.getHours() +
          (data.selHour["dName"] ? Number(data.selHour["dName"]) : 0)
      );
      obj.ToDate.setMinutes(obj.FromDate.getMinutes());
    } else if (data.display === "wod") {
      if (!data.selPULoc["dName"] || !data.selDOLoc["dName"]) {
        toast.error("Please select the locations");
        return true;
      }
      obj.BookingDay = globalService.dateDiff(data.fromDate, data.toDate);
      obj.Hours = 8;
    } else if (data.display === "wd") {
      if (!data.selBooking["dName"]) {
        toast.error("Please select the booking type");
        return true;
      } else if (data.selBooking["dName"] === "Full Day") {
        if (!data.selDay["dName"]) {
          toast.error("Please select the day ");
          return true;
        }
        obj.ToDate.setDate(
          obj.FromDate.getDate() +
            (data.selDay["dName"] ? Number(data.selDay["dName"]) : 0)
        );
        obj.ToDate.setHours(obj.FromDate.getHours());
        obj.ToDate.setMinutes(obj.FromDate.getMinutes());
      } else {
        obj.ToDate.setDate(obj.FromDate.getDate());
        obj.ToDate.setHours(obj.FromDate.getHours() + 1);
        obj.ToDate.setMinutes(obj.FromDate.getMinutes());
        obj.Hours = 1;
      }
      if (data.selBooking["dName"])
        obj.Hours =
          data.selBooking["dName"] === "Pickup & Drop"
            ? 1
            : data.selBooking["dName"] === "Half Day"
            ? 4
            : data.selBooking["dName"] === "Full Day"
            ? 8
            : 0;
    }
    sessionStorage.removeItem(appString.ON_SEARCH);
    sessionStorage.setItem(appString.ON_SEARCH, JSON.stringify(obj));

    this.props.history.push("/site/search");
  };

  render() {
    const {
      carType,
      selType,
      display,
      fromDate,
      toDate,
      bookingType,
      selBooking,
      days,
      hourList,
      locList,
      selDay,
      selPULoc,
      selDOLoc,
      selHour,
    } = this.state;

    var selDate = new Date(fromDate);
    selDate.setDate(selDate.getDate() + 1);

    return (
      <React.Fragment>
        <div className="r-slider-serach form-search dark">
          <form>
            <div className="form-title form-title-large">
              <span className="r-form-icon">
                <img src={footerFormIcon} alt="" />
              </span>
              <h2>
                Search Your <b>Best Cars</b>
              </h2>
              <small> 120+ CARS TYPE &amp; BRANDS </small>
            </div>
            <div className="row row-inputs">
              <div className="col-sm-12 py-2">
                <MSelect
                  listData={carType}
                  label="Select car type"
                  onChange={this.onChangeCarType}
                  selected={selType}
                  name={"carType"}
                />
              </div>
              {display === "wd" && (
                <React.Fragment>
                  <div className="col-sm-12 py-2">
                    <MSelect
                      listData={bookingType}
                      label="Select booking type"
                      onChange={this.handleSelectChange}
                      selected={selBooking}
                      name={"selBooking"}
                    />
                  </div>

                  {selBooking["dName"] === "Full Day" && (
                    <div className="col-sm-12 py-2">
                      <MSelect
                        listData={days}
                        label="Day"
                        onChange={this.handleSelectChange}
                        selected={selDay}
                        name={"selDay"}
                      />
                    </div>
                  )}
                  <div className="col-sm-12 py-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        variant="inline"
                        label="Start Date"
                        className="w-100"
                        name="fromDate"
                        value={fromDate}
                        onChange={(val: any) =>
                          this.handleDateChange(val, "fromDate")
                        }
                        autoOk
                        disablePast
                        format="dd/MM/yyyy hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </React.Fragment>
              )}
              {display === "wod" && (
                <React.Fragment>
                  <div className="col-sm-6 py-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        variant="inline"
                        label="Picking Up Date"
                        name="selPUTime"
                        value={fromDate}
                        onChange={(val: any) =>
                          this.handleDateChange(val, "fromDate")
                        }
                        autoOk
                        disablePast
                        format="dd/MM/yyyy hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="col-sm-6 py-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        variant="inline"
                        label="Dropping off Date"
                        value={toDate}
                        name="selDOTime"
                        onChange={(val: any) =>
                          this.handleDateChange(val, "toDate")
                        }
                        autoOk
                        minDate={selDate}
                        disablePast
                        format="dd/MM/yyyy hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-sm-6 py-2">
                    <MSelect
                      listData={locList}
                      label="Pickup location"
                      onChange={this.handleSelectChange}
                      selected={selPULoc}
                      name="selPULoc"
                    />
                  </div>
                  <div className="col-sm-6 py-2">
                    <MSelect
                      listData={locList}
                      label="Dropping Location"
                      onChange={this.handleSelectChange}
                      selected={selDOLoc}
                      name="selDOLoc"
                    />
                  </div>
                </React.Fragment>
              )}
              {display === "limoz" && (
                <React.Fragment>
                  <div className="col-sm-12 py-2">
                    <MSelect
                      listData={hourList}
                      label="Hour"
                      onChange={this.handleSelectChange}
                      selected={selHour}
                      name="selHour"
                    />
                  </div>
                  <div className="col-sm-12 py-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        variant="inline"
                        label="Start Date"
                        className="w-100"
                        value={fromDate}
                        name="fromDate"
                        onChange={(val: any) =>
                          this.handleDateChange(val, "fromDate")
                        }
                        autoOk
                        disablePast
                        format="dd/MM/yyyy hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="form-footer">
              <div className="inner clearfix">
                <button
                  className="btn m-auto d-block btn-full"
                  onClick={this.onSearch}
                >
                  Find Car
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default CarSearch;
