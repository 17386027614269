import * as React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import globalService from "../../services/globalService";
import { toast } from "react-toastify";
import { appString } from "../../common/appString";
import FetchingData from "../../components/FetchingData";
import NoDataFound from "../../components/NoDataFound";
import { format, isAfter } from "date-fns";
import AppleStoreIcon from "../../assets/images/appleStore.png";
import googlePlayIcon from "../../assets/images/googlePlay.png";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface BookingDetailProps {}

export interface BookingDetailState {}

class BookingDetail extends React.Component<
  BookingDetailProps,
  BookingDetailState
> {
  constructor(props) {
    super(props);
    const { match } = props;

    const bookingId = match.params.id;

    if (bookingId) {
      this.state.bookingId = bookingId;
    }
  }
  state = {
    bookingId: "",
    isLoggedIn: false,
    userDetail: {
      Authorization: {},
      RoleId: "",
      UserInfo: {},
    },
    isLoading: false,
    bookingDetail: {} as any,
    notifyList: [],
    isOpen: false,
    advPay: 0,
  };

  componentDidMount() {
    // this.getUserDetail();
    this.getInit();
  }

  async getInit() {
    await this.getUserDetail();
    if (this.state.isLoggedIn) {
      this.getBookingDetail();
    }
  }

  getUserDetail() {
    let user = globalService.getUserSession();
    if (user) {
      this.setState({ isLoggedIn: true, userDetail: user });
      //   this.getBooking();
    } else this.setState({ isLoggedIn: false });
  }

  getBookingDetail = async () => {
    const { bookingId } = this.state;
    this.setState({ isLoading: true });
    const { data: resp } = await globalService.getBookingDetailById(bookingId);
    // debugger;
    if (resp.status === "success") {
      this.setState({
        bookingDetail: resp.response === null ? {} : resp.response,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
      toast.error(resp.response);
    }
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ currentTab: newValue });
  };

  handlePay = async () => {
    const { data: resp } = await globalService.getBookedAdvPay(
      this.state.bookingId
    );
    if (resp.status === "success") {
      let advPay = resp.response.AdvanceAmount;
      this.setState({ isOpen: true, advPay });
    } else {
      toast.error(resp.response);
    }
  };

  handleContinuePayment = async () => {
    const { data: resp } = await globalService.getPaymentURLByBookID(
      this.state.bookingId
    );
    if (resp.status === "success") {
      localStorage.setItem("pAuth", JSON.stringify(resp.Auth));
      localStorage.setItem("lastBookId", this.state.bookingId);

      let redirectURL = resp.response.RedirectUrl;
      if (redirectURL) window.open(redirectURL, "_blank");
      this.setState({ isOpen: false });
    } else {
      localStorage.removeItem("pAuth");
      localStorage.removeItem("lastBookId");
      toast.error(resp.response);
    }
  };
  render() {
    const { bookingDetail: bDetail, isOpen, advPay, isLoading } = this.state;
    return (
      <React.Fragment>
        <div className="nav-ext d-none d-sm-block"></div>

        <Dialog
          open={isOpen}
          onClose={() => this.setState({ isOpen: !isOpen })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title"> Pay Now</DialogTitle>
          <DialogContent>
            <div>
              You have pay advanced amount{" "}
              <strong className="text-primary">{advPay} KWD</strong>
            </div>

            {/* <div className="text-center my-4">
              <button className="btn btn-danger btn-sm mx-2">Cancel</button>
              <button className="btn btn-danger btn-sm mx-2">Continue</button>

            </div> */}

            {/* <div className="row text-center">
              <div className="col-12 col-md">
                <a
                  target="_blank"
                  href={
                    "https://play.google.com/store/apps/details?id=com.app.princelessuser&hl=en"
                  }
                >
                  <img height={60} src={googlePlayIcon} alt="" />
                </a>
              </div>
              <div className="col-12 col-md">
                <a
                  target="_blank"
                  href="https://apps.apple.com/za/app/princess-q8-driver/id1447744177"
                >
                  <img height={60} src={AppleStoreIcon} alt="" />
                </a>
              </div>
            </div>

            <div className=" my-4 text-center text-secondary">
              <small>
                Please use the Princess Car App to pay on Mobile Application
              </small>
            </div> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ isOpen: !isOpen })}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleContinuePayment}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <div className="container mb-5">
          <Box
            boxShadow={4}
            style={{
              background: "rgba(255,255,255,0.6)",
              borderRadius: 3,
            }}
          >
            {bDetail.Id && this.renderDetail()}
            {!bDetail.Id && !isLoading && (
              <div className="my-5 py-5 h2 text-center font-weight-bold">
                Booking not found
              </div>
            )}
          </Box>
        </div>
      </React.Fragment>
    );
  }

  renderDetail() {
    const { bookingDetail: bDetail } = this.state;

    let fromDate = bDetail.BookingFrom;

    let isExpired = isAfter(new Date(), new Date(fromDate));

    return (
      <div className="row ml-0">
        <div className="col-4 bg-light">
          {/* <img *ngIf="!bDetail.Images" src="../../../../assets/img/empty.jpg" className="w-100 rounded m-2 border shadow" alt=""> */}

          {/* <div>
            <h1>isExpired : {isExpired && <strong>Yes - True</strong>}</h1>
          </div> */}

          {bDetail.Images && (
            <img
              src={appString.IMAGE_PATH + bDetail.Images[0].Name}
              className="w-100 rounded m-2 border shadow"
              alt=""
            />
          )}
          <div className="text-center my-4">
            <hr />
            <h2>{bDetail.Car}</h2>
            <h4>
              {bDetail.Brand} - {bDetail.Model}
            </h4>
            <h5>{bDetail.RegisterNumber}</h5>
          </div>
        </div>
        <div className="col-8">
          <div className="bg-light my-2 p-1 px-2 font-weight-bold text-primary">
            Booking Detail
          </div>
          <div className="row p-2">
            <div className="col-6 mt-2">
              <small className="text-secondary"> Booking From</small>
              <br />
              <strong>
                {format(new Date(bDetail.BookingFrom), "dd/MM/yyyy hh:mm a")}
              </strong>
            </div>
            <div className="col-6 text-right mt-2">
              <small className="text-secondary"> Booking Till</small>
              <br />
              <strong>
                {format(new Date(bDetail.BookingTo), "dd/MM/yyyy hh:mm a")}
              </strong>
            </div>

            {bDetail.PickupId != 0 && (
              <div className="col-6 mt-2">
                <small className="text-secondary"> Pickup</small>
                <br />
                <strong>{bDetail.PickupPlaceInfo["Name"]}</strong>
              </div>
            )}

            {bDetail.DropId != 0 && (
              <div className="col-6 text-right mt-2">
                <small className="text-secondary"> Drop</small>
                <br />
                <strong>{bDetail.DropPlaceInfo.Name}</strong>
              </div>
            )}

            <div className="col-12 mt-2">
              <small className="text-secondary">With Driver</small>
              <br />
              {bDetail.WithDriver ? (
                <div className="font-weight-bold text-primary">YES</div>
              ) : (
                <div className="font-weight-bold text-secondary">NO</div>
              )}
            </div>

            {/* {bDetail.CarType != "LIMOUSINE" && (
              <div className="col-12 mt-2  py-1">
                <strong className="text-primary">Package: </strong>
                <span className="text-secondary font-weight-bold">
                  {bDetail.PackageDay} Days
                </span>
                {bDetail.WithDriver && (
                  <span className="text-secondary font-weight-bold">
                    {" "}
                    for {bDetail.PackageHrs}{" "}
                  </span>
                )}
              </div>
            )} */}

            {/* {bDetail.CarType == "LIMOUSINE" && (
              <div className="col-12 mt-2 mr-2  py-1">
                <strong className="text-primary">Package: </strong>
                <span className="text-secondary font-weight-bold">
                  {bDetail.PackageDay} Days
                </span>
                <span className="text-secondary font-weight-bold">
                  {" "}
                  for {bDetail.PackageLimzHrs}{" "}
                </span>
              </div>
            )} */}
          </div>

          <div className="bg-light my-2 p-1 px-2 font-weight-bold text-primary">
            Amount Detail
          </div>
          <div className="row p-2">
            <div className="col-4 mt-2">
              <small className="text-secondary">Booking Amount</small>
              <br />
              <strong>{bDetail.BookingAmount} KWD</strong>
            </div>
            <div className="col-4 text-center mt-2">
              <small className="text-secondary">Advance Amount</small>
              <br />
              <strong>{bDetail.AdvanceAmount} KWD</strong>{" "}
              <small className="ml-2">({bDetail.AdvancePercentage} %)</small>
              {bDetail.AdvanceAmount > 0 && !isExpired && !bDetail.IsConfirmed && (
                <Button
                  className="ml-2"
                  variant="outlined"
                  color="primary"
                  onClick={this.handlePay}
                >
                  PAY NOW
                </Button>
              )}
              {bDetail.IsConfirmed && (
                <span className="badge badge-success mx-3">Paid</span>
              )}
            </div>
            <div className="col-4 mt-2 text-right">
              <small className="text-secondary">Balance</small>
              <br />
              <strong>{bDetail.BalanceAmount} KWD</strong>
            </div>

            <div className="col-12 mt-5 mb-2 text-center text-secondary">
              <small>
                Note: Balance amount should be paid while get the Car
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingDetail;
