import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export interface MSelectProps {
  listData: any[];
  label: string;
  selected: string;
  onChange: any;
  name?: any;
}

const MSelect: React.SFC<MSelectProps> = ({
  listData,
  label,
  selected,
  onChange,
  name
}) => {
  return (
    <FormControl className="w-100">
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={selected ? selected : ""}
        onChange={onChange}
        inputProps={{
          name: { name }
        }}
      >
        {listData.length === 0 && <MenuItem>No Data</MenuItem>}
        {listData.length > 0 &&
          listData.map((data, idx) => {
            return (
              <MenuItem key={idx} value={data}>
                {data.dName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default MSelect;
